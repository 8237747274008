.favourite-promoters-wrapper {
  .box-header {
    font-size: 20px;
    height: 55px;
    line-height: 56px;
    font-family: "Bilo", "Poppins", "Helvetica", "Arial", "sans-serif";
    color: #151a40;
    font-weight: 500;
    margin: 0;
    color: inherit;
    letter-spacing: -0.5px;
    text-rendering: optimizelegibility;

    button {
      float: right;
      font-family: "Arial", "serif";
      font-size: 12px;
      margin-right: 20px;
      color: #10345d;
    }
  }

  .items-list {
    padding: 0px 20px 1px 0px;
    list-style-type: none;
    display: flex;

    &.no-border {
      background: none;
      padding-bottom: 0px;
    }
  }
}
