.friends-list {
  list-style-type: none;
  padding: 0;
  padding-bottom: 10px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.no-friends-found-container {
  .message {
    display: block;

    .inner {
      padding: 26px 0px 28px 0px;
      text-align: center;

      h3 {
        font-size: 20px;
        line-height: 24px;
        font-family: "Bilo", "Poppins", "Helvetica", "Arial", "sans-serif";
        color: #151a40;
        font-weight: 500;
        margin: 0;
        color: inherit;
        letter-spacing: -0.5px;
        text-rendering: optimizelegibility;
      }
    }
  }
}
