@import "../../definitions";

div#tags-wrapper {
  line-height: 20px;
  padding-bottom: 18px;
}
.box-header {
  background: none;
  height: 54px;
  font-size: 20px;
  height: 55px;
  line-height: 56px;
}
ul.tags {
  list-style-type: none;
  overflow: hidden;
}
a#all-tags {
  background: #10345d;
  color: white;
  font-weight: bold;
  line-height: 20px;
  height: 20px;
  margin: 0px 20px 2px 20px;
  padding: 0px 22px 0px 20px;
  text-decoration: none;
  .show {
    display: inline-block;
    background: url(#{$domain}/img/web/expand-tags-arrows.gif) no-repeat top right;
    padding: 0px 12px 0px 6px;
  }
}
.tags.no-padding {
  margin: 0 0 18px;
}
.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
div.categories-list {
  max-width: 100%;
}
