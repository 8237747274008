.order-card-wrapper {
  width: 250px;
  height: 400px;
  text-align: left;
  position: relative;

  .ticket-card {
    &:nth-child(1) {
      z-index: 10;
    }

    &:nth-child(2) {
      left: 20px;
      top: 2px;
      z-index: 9;
      -webkit-transform: rotate(2.5deg);
      -moz-transform: rotate(2.5deg);
      transform: rotate(2.5deg);
    }

    &:nth-child(3) {
      left: 24px;
      top: 4px;
      z-index: 8;
      -webkit-transform: rotate(5deg);
      -moz-transform: rotate(5deg);
      transform: rotate(5deg);
    }
  }
}
