.event-keyword-card {
  background-color: #fff;
  border: 1px solid #f2f2f5;
  border-radius: 3px;
  font-size: 0.835rem;
  font-weight: 600;
  color: rgba(69, 69, 68, 0.8);
  padding: 12px 24px;
  margin: 0 3px 5px 0;
  transition: 0.3s all;
  height: 50px;
  display: inline-block;

  &:hover {
    background-color: #f2f2f5;
    text-decoration: none;
    color: #454544;
  }
}
