.notification-setting-switch {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", "Arial", "sans-serif";
  font-size: 16px;
  line-height: 22px;
  color: #444;

  .title-container {
    color: #888;
    font-size: 11px;

    strong {
      color: #444;
      display: block;
      font-size: 12px;
    }
  }

  .switch {
    color: #888;
    cursor: pointer;
    height: 26px;
    margin: 0px;
    overflow: hidden;
    padding: 0px !important;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    width: 60px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: 12px;

    &.unchecked {
      .on {
        left: -42px;
        z-index: 1;
      }

      .thumb {
        left: 2px;
      }

      .off {
        left: 0px;
        z-index: 2;
      }
    }

    span {
      display: block;
      height: 26px;
      line-height: 26px;
      position: absolute;
      top: 0px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      -webkit-transition: left 0.1s ease-in-out;
      -moz-transition: left 0.1s ease-in-out;
      -o-transition: left 0.1s ease-in-out;
      transition: left 0.1s ease-in-out;

      &.on {
        background: #10345d;
        color: white;
        left: 0px;
        padding: 0px 16px 0px 0px;
        width: 44px;
        z-index: 2;
      }

      &.thumb {
        background: white;
        height: 22px;
        left: 44px;
        top: 2px;
        width: 14px;
        z-index: 3;
        -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
      }

      &.off {
        background: #d2d2d2;
        color: white;
        left: 42px;
        padding: 0px 0px 0px 16px;
        width: 44px;
        z-index: 1;
      }
    }
  }
}
