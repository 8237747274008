@import "../../_definitions";

.full-width.bg-pale1 {
  position: relative;
  padding: 4rem 2rem;
  background-color: #fff6fc;
}
.container-medium {
  max-width: 60rem;
  margin-right: auto;
  margin-left: auto;
  h2 {
    margin-top: 10px;
    margin-bottom: 2rem;
    font-family: Bilo, sans-serif;
    color: #151a40;
    font-size: 3.4rem;
    line-height: 110%;
    font-weight: 700;
    letter-spacing: -0.5px;
    text-transform: capitalize;
  }
}
.div-block-4 {
  display: flex;
  margin-bottom: 2rem;
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
}
.bullet-checkbox {
  margin-right: 8px;
}
video {
  display: inline-block;
  vertical-align: baseline;
}
.w-background-video {
  position: relative;
  overflow: hidden;
  height: 500px;
  color: white;
}
.background-video {
  z-index: 2;
  height: 682px;
  max-width: 958px;
  margin-right: auto;
  margin-bottom: -140px;
  margin-left: auto;
  border-radius: 16px;
}

.w-background-video > video {
  background-size: cover;
  background-position: 50% 50%;
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  right: -100%;
  bottom: -100%;
  top: -100%;
  left: -100%;
  object-fit: cover;
  z-index: -100;
}
._w-50 {
  width: 50%;
  margin-right: 2rem;
}

//////////////
.container-main.mt-6.features {
  padding-top: 8rem;
}
.container-main.mt-6 {
  margin-top: 6rem;
}
.heading {
  text-align: center;
  @media screen and (max-width: 575px) {
    font-size: 2rem !important;
  }
}
.features-row {
  display: flex;
  max-width: 100rem;
  margin: 6rem auto 10rem;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    margin-top: 2rem;
    margin-bottom: 4rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.features-content {
  display: flex;
  max-width: 600px;
  padding-left: 4rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  @media (max-width: 991px) {
    padding-left: 0;
    max-width: 100%;
  }
  h3 {
    margin-top: 20px;
    margin-bottom: 2rem;
    font-family: Bilo, sans-serif;
    color: #151a40;
    font-size: 2rem;
    line-height: 130%;
    font-weight: 700;
    text-transform: capitalize;
  }
  p {
    margin-bottom: 1rem;
    color: #5b5b5e;
    font-size: 1.1rem;
    line-height: 150%;
    font-weight: 300;
  }
}
.mb-custom-1 {
  margin-bottom: 1rem;
}
.paragraph-l {
  font-size: 1.25rem;
}
.features-row.flipped {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}
.features-content.flipped {
  max-width: 600px;
  padding-right: 4rem;
  padding-left: 0rem;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-right: 0;
  }
}
.label-s {
  font-family: Bilo, sans-serif !important;
  color: #f879c9;
  font-size: 0.8rem !important;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.full-width-with-image {
  display: flex;
  max-width: 1720px;
  min-height: 540px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url(#{$domain}/img/organizers/happy-african-businessman-reading-papers-and-shaki-TH4NMZQ-1.jpeg);
  background-position: 0px 0px;
  background-size: 65%;
  background-repeat: no-repeat;
  @media screen and (max-width: 991px) {
    min-height: auto;
    padding: 2rem 2rem 4rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(252, 251, 253, 0.6)),
        to(rgba(252, 251, 253, 0.6))
      ),
      url(#{$domain}/img/organizers/happy-african-businessman-reading-papers-and-shaki-TH4NMZQ.jpg);
    background-image: linear-gradient(180deg, rgba(252, 251, 253, 0.6), rgba(252, 251, 253, 0.6)),
      url(#{$domain}/img/organizers/happy-african-businessman-reading-papers-and-shaki-TH4NMZQ.jpg);
    background-position: 0px 0px, 0px 0px;
    background-size: auto, cover;
    background-repeat: repeat, no-repeat;
  }
}
.div-block-13 {
  width: 60%;
}
.content-text {
  max-width: 60rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 6rem;
  h2 {
    margin-top: 10px;
    margin-bottom: 2rem;
    font-family: Bilo, sans-serif;
    color: #151a40;
    font-size: 3.4rem;
    line-height: 110%;
    font-weight: 700;
    letter-spacing: -0.5px;
    text-transform: capitalize;
  }
}
.btn-m.secondary {
  background-color: #863ee2;
  color: #fff;
}
.btn-m {
  padding: 0.6rem 3rem;
  border-radius: 40px;
  color: #450ab8;
  font-size: 1.2rem;
  line-height: 160%;
  text-decoration: none;
}
.container-main.morefeatures {
  margin-top: 6rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
}
.bg-solid.pale {
  background-color: #fff6fc;
}
.more-features {
  padding-top: 1rem;
  padding-bottom: 1rem;
  h3 {
    margin-top: 20px;
    margin-bottom: 2rem;
    font-family: Bilo, sans-serif;
    color: #151a40;
    font-size: 2rem;
    line-height: 130%;
    font-weight: 700;
    text-transform: capitalize;
  }
}
.grid-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
  h4 {
    margin-top: 10px;
    margin-bottom: 1rem;
    font-family: Bilo, sans-serif;
    color: #151a40;
    font-size: 1.5rem;
    line-height: 130%;
    font-weight: 700;
    letter-spacing: -0.5px;
    text-transform: none;
  }
  .paragraph-s {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 1rem;
    color: #5b5b5e;
  }
}
.label-comingsoon {
  display: inline-block;
  padding: 0.1rem 1rem;
  border-radius: 20px;
  background-color: #d5a2fa;
  color: #fff;
  font-size: 0.7rem;
  line-height: 160%;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.background-video {
  @media screen and (max-width: 479px) {
    display: none;
  }
}
