.interest-tag {
  border: 1px solid #e2e2e2;
  color: #888;
  float: left;
  line-height: 18px;
  margin: 0px 2px 2px 0px;
  padding: 10px 15px;

  .remove-tag {
    background: url(https://local.evedo.co/img/web/icon-remove-gray.gif) no-repeat center right;
    display: inline-block;
    height: 18px;
    vertical-align: top;
    width: 13px;
    color: #10345d;
    border: none;
  }
}
