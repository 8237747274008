.achievement-card {
  background: no-repeat 16px 20px;
  color: #888;
  line-height: 16px;
  margin-left: 20px;
  position: relative;
  text-align: center;
  width: 122px;
  margin-bottom: 1rem;

  img {
    height: 122px;
    padding: 16px 0;
    margin: auto;
  }

  strong {
    color: #444;
    display: block;
    overflow: hidden;
    padding: 0px 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 112px;
    margin: auto;
    text-align: center;
    height: 18px;
  }

  .target {
    background: #ff007b;
    color: white;
    font-size: 14px;
    font-weight: bold;
    height: 28px;
    min-width: 28px;
    left: 82px;
    line-height: 28px;
    padding: 0px 5px;
    position: absolute;
    top: 19px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    border-radius: 14px;
  }
}
