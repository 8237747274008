.people-widget a {
  float: left;
  display: block;
  float: left;
  height: 51px;
  margin: 0px 2px 2px 0px;
  overflow: hidden;
  position: relative;
  width: 51px;
  img {
    height: 50px;
    width: 50px;
  }
  span {
    background: #10345d;
    color: white;
    display: block;
    font-size: 11px;
    height: 17px;
    line-height: 17px;
    overflow: hidden;
    padding: 0px 3px;
    text-align: center;
    text-overflow: ellipsis;
    top: 51px;
  }
}
.past-events {
  padding: 10px 10px 0px 10px;
  @media (max-width: 990px) {
    display: flex;
    justify-content: center;
  }
}
.past-events-widget ul {
  list-style-type: none;
  padding: 0px;
  li {
    color: #d2d2d2;
    font-size: 11px;
    height: 44px;
    line-height: 15px;
    padding: 6px 0px 0px 50px;
    position: relative;
    width: 160px;
    @media (max-width: 990px) {
      width: fit-content;
    }
    img {
      height: 40px;
      left: 0px;
      position: absolute;
      top: 0px;
      width: 40px;
    }
    a {
      display: block;
      font-size: 12px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 150px;
      @media (max-width: 990px) {
        width: fit-content;
      }
    }
    .details {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 150px;
      span {
        padding: 0px 4px;
      }
    }
  }
}
