.message-friend-modal-wrapper {
  display: block !important;
  z-index: 1000;

  .message-friend-modal {
    .modal-content {
      .modal-header {
        background: white !important;
        color: #525252;
        height: 55px;
        h3 {
          font-size: 20px;
          line-height: 24px;
          font-family: "Bilo", "Poppins", "Helvetica", "Arial", "sans-serif";
          color: #151a40;
          font-weight: 500;
          margin: 0;
          color: inherit;
          letter-spacing: -0.5px;
          text-rendering: optimizelegibility;
        }

        .close {
          padding: 1rem 1rem;
          margin: -1rem -1rem -1rem auto;
          background-color: transparent;
          float: right;
          font-size: 1.25rem;
          font-weight: 700;
          line-height: 1;
          color: #000;
          text-shadow: 0 1px 0 #ffffff;
          opacity: 0.5;
        }
      }

      .message-form {
        margin: 0 0 18px ;
        // width: 100% !important;
        .model-body {
          padding-left: 16px !important;
        }

        textarea {
          margin-top: 0px;
          margin-bottom: 0px;
          height: 75px;
        }

        .btn.btn-primary {
          background-color: #e62e83;
          padding: 0.735rem 1rem;
          border-radius: 2px;
          font-family: "Poppins", "HelveticaNeue", "Helvetica", "Arial", "sans-serif";
          border: 0;

          &:hover {
            background: #0e2c4f;
          }
        }
      }
    }
  }
}
