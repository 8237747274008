@import "../../../_definitions";

.cta-section {
  background: #fcfbfd;
  border-radius: 16px;
  .link-normal {
    &::after {
      display: none;
    }
  }
}
.cta-bg {
  background: linear-gradient(3.6deg, #ffedee 0%, #ffd7f3 48.44%, #f2efff 100%);
}
.cta-content {
  padding: 30px 15px;
  @media all and (min-width: 572px) {
    padding: 60px 50px;
  }
}
.trusted {
  .text-s {
    font-family: "Bilo", "Poppins", Helvetica, Arial, sans-serif;
    white-space: nowrap;
    font-weight: 700;
  }
}
.trusted-logo {
  min-width: 0;
  margin: 0 16px;
  width: 100px;
}
.cta-tooltip {
  background-color: rgba(21, 26, 64, 0.66);
  padding: 24px 18px 24px 41px;
  width: 60%;
  transition: 0.3s ease;
  color: #fff;
  @media all and (max-width: 991px) {
    opacity: 1;
    bottom: 0;
  }
  @media all and (max-width: 571px) {
    width: 100%;
    &::before {
      left: 45%;
    }
  }
  p{
    color: white;
  }
}
.button-main {
  color: #fff;
  border-radius: 80px;
  padding: 10px 50px;
  font-weight: 500;
  font-size: 1rem;
  border: 0;
  letter-spacing: 0.01rem;
  cursor: pointer;
}

.cta-overlay-image{
  top: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}