@import "../../definitions";
.activity-calendar {
  .year {
    background: #e2e2e2;
    border-top: 1px solid #e2e2e2;
    color: white;
    display: block;
    font-size: 14px;
    font-weight: bold;
    height: 20px;
    line-height: 20px;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  }

  .activity-btn {
    width: 100%;
    padding-left: 30px;
    border-top: 1px solid #e2e2e2;
    color: #888;
    display: block;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    position: relative;
    text-decoration: none;
    text-align: left;

    &:last-child {
      border-bottom: 1px solid #e2e2e2;
    }
  }

  .active {
    background: #10345d url(#{$domain}/img/web/profile-activities-active-month.jpg) repeat-y top left;
    border-top: none;
    color: white;
    height: 51px;
    line-height: 52px;

    &:before {
      content: url(#{$domain}/img/web/profile-activities-active-arrow.jpg);
      left: 0px;
      position: absolute;
      top: 5px;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
