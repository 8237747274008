.no-friends-container {
  background: none;
  height: 200px;
  position: relative;
  text-align: center;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  .dashed-border {
    div {
      padding: 26px 0px 28px 0px;
      text-align: center;

      h3 {
        font-size: 20px;
        line-height: 24px;
        font-family: "Bilo", "Poppins", "Helvetica", "Arial", "sans-serif";
        color: #151a40;
        font-weight: 500;
        margin: 0;
        color: inherit;
        letter-spacing: -0.5px;
        text-rendering: optimizelegibility;
      }
    }
  }
}
