@import "../../_definitions";

.sign-wrapper form {
  input::placeholder {
    opacity: 0.5;
  }
  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    opacity: 0.5;
  }
  input::-ms-input-placeholder {
    /* Microsoft Edge */
    opacity: 0.5;
  }
  margin: 0 0 18px;
  label {
    display: inline-block;
    margin: 0px;
    padding: 15px 0px !important;
    width: 230px;
  }
}
form.standard label {
  line-height: 13px;
  padding: 20px 0px 0px 0px;
}
.w-auto {
  width: auto !important;
  padding-top: 10px;
  margin: 0;
}
