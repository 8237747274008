.friends-menu {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", "Arial", "sans-serif";
  font-size: 16px;
  line-height: 22px;
  color: #444;

  .button-tabs.nav {
    width: 100%;

    button {
      color: #888;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      line-height: 18px;
      position: relative;
      text-align: center;

      &.active {
        background: #10345d;
        color: white;

        &:before {
          border-color: #10345d transparent transparent transparent;
          border-style: solid;
          border-width: 10px 10px 0px 10px;
          bottom: -10px;
          content: "";
          display: block;
          height: 0px;
          left: 50%;
          margin-left: -10px;
          position: absolute;
          width: 0px;
          z-index: 1;
          -webkit-transform: rotate(360deg);
          -moz-transform: rotate(360deg);
          transform: rotate(360deg);
        }

        strong,
        span {
          color: white;
        }
      }

      strong {
        margin: 12px 0px 3px 0px;
        color: #444;
        font-size: 16px;
        line-height: 1;
        display: block;
        padding: 3px 0px;
      }

      span {
        display: block;
        padding: 3px 0px;
      }
    }
  }
}
