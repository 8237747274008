.white-bg {
  background: white;
}
.sidebar-widget {
  -webkit-box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 12%);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 12%);

  h1 {
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: -0.5px;
    color: #10345d;
  }
  h2 {
    font-size: 1rem;
    color: rgba(120, 125, 130, 0.8);
    font-family: "Open Sans";
    font-weight: 400;
    letter-spacing: 0;
  }
  h3 {
    color: #b2b2b2 !important;
    font-family: "Maven Pro", Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding: 11px 0px;
  }
}
.price-line {
  color: #608cbe;
  img {
    width: 20px;
    height: 20px;
  }
  .date-highlight {
    font-size: 1rem;
    text-transform: uppercase;
    color: #10345d;
    font-weight: 600;
    display: inline-block;
    border-right: 1px solid rgba(152, 183, 220, 0.3);
    padding: 0px;
  }
}
.edit-texts {
  display: flex;
  input {
    border: none !important;
    box-shadow: none !important;
    width: 100px;
  }
  button {
    width: 100px;
  }
}
.edit {
  font-size: 11px;
  height: 40px;
  line-height: 38px;
  span:first-child {
    color: #10345d;
    display: inline-block;
  }
  span:last-child {
    color: #888;
    display: none;
  }
}
.btn-primary:hover {
  background-color: #d60d6a !important;
  border-color: #d60d6a !important;
  color: white !important;
}
a.disable {
  pointer-events: none;
  cursor: default;
  background-color: #647c96;
  border-color: #647c96;
}
.sticky-top {
  top: 20px !important;
  z-index: 0 !important;
}
