.box {
  padding: 2rem;
  border-radius: 4px;
  height: 100%;
  background-color: white;
  width: 100%;
}

.container {
  display: flex;
  justify-content: space-between;
}

.heading {
  font-size: 1.2rem;
  color: black;
  font-weight: bold;
}

.subHeading {
  font-weight: bold;
  color: gray;
  margin-top: 1rem;
}

.numberBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

.number {
  font-size: 2rem;
  color: black;
  font-weight: bold;
  text-align: center;
}
