.privacy-setting-switch {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", "Arial", "sans-serif";
  font-size: 16px;
  line-height: 22px;
  color: #444;

  .title-container {
    color: #888;
    font-size: 11px;

    strong {
      color: #444;
      display: block;
      font-size: 12px;
    }
  }

  .title-container + label {
    -webkit-border-radius: 2px 0px 0px 2px;
    -moz-border-radius: 2px 0px 0px 2px;
    border-radius: 2px 0px 0px 2px;
  }

  label {
    font-size: 12px;
    background: #10345d;
    color: white;
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    padding: 0px;
    text-align: center;
    width: 111px;

    &.unchecked {
      background: #f0f0f0;
      color: #888;
    }
  }
}
