@import "./definitions";

body {
  background-color: #fff;
  font-family: Poppins, sans-serif;
  // font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
}
a {
  color: #10345d;
  text-decoration: none;
}

.text-pink {
  color: $primary-pink;
  &:hover {
    color: $secondary-pink;
  }
}
.container {
  max-width: 1220px;
}
.text-white {
  color: #fff !important;
}
.text-grey {
  color: $text-grey;
}
.text-light-grey {
  color: $light-grey;
}
.text-dark {
  color: #23232e !important;
}
.text-dark-grey {
  color: $dark-grey;
}
.text-dark-blue {
  color: $dark-blue;
}
a.text-grey {
  &:hover {
    color: $dark;
  }
}
.bg-pink {
  background-color: $primary-pink;
  &:hover {
    background-color: $secondary-pink;
  }
}
.bg-pink-light {
  background-color: $light-pink;
}
.bg-violet-light {
  background-color: $light-violet;
}
.text-violet {
  color: $secondary-violet;
  &:hover {
    color: $primary-violet;
  }
}
.text-mode-dark {
  color: $light-mode-text !important;
}
.bg-violet {
  background-color: $secondary-violet;
  &:hover {
    background-color: $primary-violet;
  }
}
.bg-pink-pale {
  background-color: #f9edfe;
}
.bg-dark-violet {
  background-color: $dark-violet;
}
.text-xl {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 38px;
}
.text-l {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 28px;
}
.text-m {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 26px;
}
.text-s {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 22px;
  letter-spacing: 0.01rem;
}
.text-xs {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 20px;
  letter-spacing: 0.01rem;
}

.label-l {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;
}
.label-m {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 600;
  font-size: 0.8125rem;
  line-height: 24px;
}
.label-s {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 600;
  font-size: 0.6875rem;
  line-height: 24px;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.cta-text {
  color: #8f8e99;
}
.link-transition {
  transition: 0.3s ease;
  &::after {
    transition: 0.3s ease;
  }
  &:hover {
    transition: 0.3s ease;
    &::after {
      transition: 0.3s ease;
    }
  }
}
.title-bilo-m {
  font-family: "Bilo", "Poppins", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 36px;
  color: #23232e;
}
.link-pink {
  color: $link-pink;
}
.transition-500 {
  transition: 0.5s ease;
  &::after {
    transition: 0.5s ease;
  }
  &:hover {
    transition: 0.5s ease;
    &::after {
      transition: 0.5s ease;
    }
  }
}
.transition-200 {
  transition: 0.2s ease;
  &::after {
    transition: 0.2s ease;
  }
  &:hover {
    transition: 0.2s ease;
    &::after {
      transition: 0.2s ease;
    }
  }
}
.dropdown-menu {
  opacity: 0;
}
.dropdown-menu.show {
  opacity: 1;
}
.widget-dropdown {
  border: 0;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  padding: 20px;
}
.nav-link-normal {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 32px;
}

.category-dropdown {
  right: -300px;
  padding: 22px 29px 36px;
  .dropdown-item {
    border-radius: 55px;
    padding: 0.5rem 1rem;
  }
  .label-m {
    color: #8f8e99;
    white-space: nowrap;
    line-height: 32px;
    border-bottom: 1px solid $grey;
  }
}
.featured-slider {
  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    height: inherit !important;
    padding-right: 20px;
    > div {
      height: 100%;
    }
  }
  .slick-dots {
    display: flex;
    align-items: center;
  }
  .slick-dots li {
    width: auto;
    margin: 0 16px 0 0;
    display: flex;
    align-items: center;
  }
  .slick-dots li div {
    height: 9px;
    width: 9px;
    background-color: $grey;
  }
  .slick-dots li.slick-active div {
    height: 12px;
    width: 12px;
    background-color: $primary-pink;
  }
}
a.current {
  background: $grey;
  border-radius: 32px;
  color: $dark;
}
.nav-link-hover {
  &:hover {
    @extend .current;
  }
}
.header-section {
  background: linear-gradient(93.6deg, #ffdbdd 0%, #ffc5ed 48.44%, #e9e4ff 100%);
}
.header-overlay-color {
  top: 0;
  left: 0;
  bottom: 0;
}
.header-overlay-image {
  top: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}
.link-normal {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 16px;
  color: $dark;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    background: $dark;
  }
  &:hover {
    color: $link-pink;
    &::after {
      width: 0;
    }
  }
}
.location-icon {
  left: 15px;
  z-index: 1;
  margin: 0;
  color: #d6d3df;
}
.search-icon {
  @extend .location-icon;
}
.nav-button-primary {
  background: $primary-pink;
  border-radius: 55px;
  padding-top: 9px;
  padding-bottom: 9px;
  white-space: nowrap;
  &:hover {
    background-color: $secondary-pink;
    color: #fff;
  }
}
.header-button {
  @extend .nav-button-primary;
  background: $primary-violet;
  &:hover {
    background: $secondary-violet;
  }
}
.header-overlay-color {
  top: 0;
  left: 0;
  bottom: 0;
}
.button-ghost {
  background: #ffffff;
  border: 1px solid #d6d3df !important;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(195, 116, 247, 0.2);
  border-radius: 32px;
  z-index: 1;
  font-weight: 600;
  font-size: 15px;
  line-height: 32px;
  text-align: center;
  padding: 10px 30px;
  white-space: nowrap;
  &-square {
    border-radius: 4px;
    border: 2px solid #5232ae;
    background: rgba(255, 255, 255, 0.6);
    &:hover {
      background: #fff;
    }
  }
  &:hover {
    border-color: #6e6d76;
  }
}
.card {
  border-radius: 1rem;
}
.card-bg {
  height: 220px;
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 4px;
  transition: 0.3s ease-in;
  &.card-horizontal {
    border-radius: 0 0 4px 4px;
  }
}
.nav-item .rounded-circle {
  width: 40px;
  height: 40px;
  &:hover {
    background: #f6f5fa;
  }
}
.card-text {
  font-family: "Bilo", "Poppins", Helvetica, Arial, sans-serif;
  line-height: 24px;
}
.e-card p.card-text {
  font-family: "Bilo", "Poppins", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #323235;
  margin-bottom: 0.8rem;
}
.show {
  .dropdown-angle-pink {
    @extend .current;
    &::after {
      border-bottom: 5px solid $primary-pink;
      border-top: unset;
    }
  }
}
.show.menu-transition-to-bottom {
  z-index: 99;
  top: 100%;
  opacity: 1;
}
.header-title {
  font-family: "Bilo", "Poppins", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 4.25rem;
  line-height: 68px;
}
@media all and (max-width: 600px) {
  .header-title {
    font-size: 2.5rem;
    line-height: 1.25;
  }
  .search-input {
    min-width: 200px;
    width: 100%;
  }
}
.header-text {
  @extend .text-l;
  color: $light-mode-text;
  font-size: 1.25rem;
  line-height: 32px;
  font-weight: 400;
  letter-spacing: 0.01em;
}
.location-input {
  width: auto;
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  background-color: rgba(241, 238, 245, 0.5);
  padding-top: 12px;
  padding-bottom: 12px;
}
.card-body {
  z-index: 1;
}
.promoter-card {
  padding-top: 100%;
  border-radius: 22px;
  &:hover {
    transform: scale(1.1);
    .promoter-content {
      bottom: -5%;
      right: -5%;
      left: -5%;
      top: -5%;
    }
    .promoter-title {
      height: 100%;
    }
  }
}
.promoter-content {
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.online-event {
  color: #6e6d76;
}
.offcanvas {
  overflow-y: scroll;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link.text-tabs {
  background-color: transparent;
  border-color: transparent;
}
.search-wrap {
  background-color: rgba(241, 238, 245, 0.6);
  &:hover {
    background-color: rgba(241, 238, 245, 1);
  }
  border: 0;
  box-sizing: border-box;
  border-radius: 32px;
  color: #8f8e99;
}
textarea:focus,
input[type="text"]:focus {
  outline: 0;
  outline: thin dotted \9;
}
.footer-icon {
  width: 18px;
}
/* input and textarea */

form.standard input,
form.standard textarea {
  padding: 12px;
  margin-top: 10px;
}
/* errors */

form.standard .error-message {
  background: #e33535;
  color: white;
  display: none;
  font-weight: bold;
  line-height: 40px;
  padding: 0px 20px;
  text-align: center;
}

form.standard .error-message.shown {
  display: block;
}

form.standard .error {
  color: #e62e83;
}

form.standard input.error:-moz-placeholder,
form.standard textarea.error:-moz-placeholder {
  color: #e62e83;
}

form.standard .error:-ms-input-placeholder {
  color: #e62e83;
}

form.standard .error::-webkit-input-placeholder {
  color: #e62e83;
}

form.standard fieldset select {
  float: left;
  margin-top: 0px;
}
form.standard label {
  line-height: 13px;
  padding: 20px 0px 0px 0px;
}

form.standard label:first-child {
  padding-top: 10px;
}

form.standard label + label {
  padding-top: 10px;
}

form.standard label + input,
form.standard label + select {
  margin-top: 20px;
}
form.standard fieldset {
  margin-top: 10px;
}

form.standard div.block {
}

form.standard div.whitespace {
  color: #d2d2d2;
  float: left;
  height: 30px;
  margin-top: 10px;
  line-height: 30px;
  text-align: center;
  width: 20px;
}

form.standard div.actions {
  padding: 21px 0px 20px 0px;
  text-align: center;
}

form.standard div.actions.no-border {
  background: none;
  padding-top: 20px;
}
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  background-color: #ffffff;
  border: 1px solid #d2d2d2;
}
.button-ghost-square {
  @extend .button-ghost;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.6);
  &:hover {
    background: #fff;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    opacity: 1;
  }
}

.highlight-tooltip {
  height: 2px;
  position: absolute;
  width: 2px;
  z-index: 10;
}
.highlight-tooltip > div {
  font-size: 12px;
  line-height: 34px;
  position: absolute;
  white-space: nowrap;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-font-smoothing: subpixel-antialiased;
  font-smoothing: subpixel-antialiased;
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0.34, 1.61, 0.7, 1) 0s, opacity 0.1s ease;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0.34, 1.61, 0.7, 1) 0s, opacity 0.1s ease;
  transition: transform 0.25s cubic-bezier(0.34, 1.61, 0.7, 1) 0s, opacity 0.1s ease;
  /* COLOR */
  /* POSITION */
  /* ICON */
}
.highlight-tooltip > div:after {
  content: '';
  display: block;
  position: absolute;
}
.highlight-tooltip > div.hidden {
  opacity: 0;
  -webkit-transform: scale(0.5, 0.5);
  -moz-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
}
.highlight-tooltip > div.green {
  background: #12982b;
  color: #ffffff !important;
}
.highlight-tooltip > div.red {
  background: #ae102e;
  color: #ffffff !important;
}
.highlight-tooltip > div.yellow {
  background: #f6a131;
  color: #ffffff !important;
}
.highlight-tooltip > div.bottom {
  padding: 0px 15px;
  top: 5px;
  -webkit-transform-origin: top center;
  -moz-transform-origin: top center;
  transform-origin: top center;
}
.highlight-tooltip > div.bottom:after {
  top: -7px;
  left: 50%;
  margin-left: -8px;
  border-color: transparent transparent #ae102e transparent;
  border-style: solid;
  border-width: 0px 8px 7px 8px;
  height: 0px;
  width: 0px;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  transform: rotate(360deg);
}
.highlight-tooltip > div.bottom.green:after {
  border-color: transparent transparent #12982b transparent;
  border-style: solid;
  border-width: 0px 8px 7px 8px;
  height: 0px;
  width: 0px;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  transform: rotate(360deg);
}
.highlight-tooltip > div.bottom.yellow:after {
  border-color: transparent transparent #f6a131 transparent;
  border-style: solid;
  border-width: 0px 8px 7px 8px;
  height: 0px;
  width: 0px;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  transform: rotate(360deg);
}
.highlight-tooltip > div.left {
  right: 8px;
  padding: 0px 10px 0px 15px;
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  transform-origin: right center;
}
.highlight-tooltip > div.left:after {
  right: -10px;
  margin-top: -16px;
  top: 50%;
  border-color: transparent transparent transparent #ae102e;
  border-style: solid;
  border-width: 16px 0px 16px 10px;
  height: 0px;
  width: 0px;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  transform: rotate(360deg);
}
.highlight-tooltip > div.left.green:after {
  border-color: transparent transparent transparent #12982b;
  border-style: solid;
  border-width: 16px 0px 16px 10px;
  height: 0px;
  width: 0px;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  transform: rotate(360deg);
}
.highlight-tooltip > div.left.yellow:after {
  border-color: transparent transparent transparent #f6a131;
  border-style: solid;
  border-width: 16px 0px 16px 10px;
  height: 0px;
  width: 0px;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  transform: rotate(360deg);
}
.highlight-tooltip > div.right {
  left: 8px;
  padding: 0px 15px 0px 10px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  transform-origin: left center;
}
.highlight-tooltip > div.right:after {
  left: -10px;
  margin-top: -16px;
  top: 50%;
  border-color: transparent #ae102e transparent transparent;
  border-style: solid;
  border-width: 16px 10px 16px 0px;
  height: 0px;
  width: 0px;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  transform: rotate(360deg);
}
.highlight-tooltip > div.right.green:after {
  border-color: transparent #12982b transparent transparent;
  border-style: solid;
  border-width: 16px 10px 16px 0px;
  height: 0px;
  width: 0px;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  transform: rotate(360deg);
}
.highlight-tooltip > div.right.yellow:after {
  border-color: transparent #f6a131 transparent transparent;
  border-style: solid;
  border-width: 16px 10px 16px 0px;
  height: 0px;
  width: 0px;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  transform: rotate(360deg);
}
.highlight-tooltip > div.top {
  bottom: 5px;
  padding: 0px 15px;
  -webkit-transform-origin: bottom center;
  -moz-transform-origin: bottom center;
  transform-origin: bottom center;
}
.highlight-tooltip > div.top:after {
  bottom: -7px;
  left: 50%;
  margin-left: -8px;
  border-color: #ae102e transparent transparent transparent;
  border-style: solid;
  border-width: 7px 8px 0px 8px;
  height: 0px;
  width: 0px;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  transform: rotate(360deg);
}
.highlight-tooltip > div.top.green:after {
  border-color: #12982b transparent transparent transparent;
  border-style: solid;
  border-width: 7px 8px 0px 8px;
  height: 0px;
  width: 0px;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  transform: rotate(360deg);
}
.highlight-tooltip > div.top.yellow:after {
  border-color: #f6a131 transparent transparent transparent;
  border-style: solid;
  border-width: 7px 8px 0px 8px;
  height: 0px;
  width: 0px;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  transform: rotate(360deg);
}
.highlight-tooltip > div div.icon {
  // background: url('../../img/web-v2/common/highlight-icons.png');
  background-size: 18px 102px;
  display: inline-block;
  height: 34px;
  margin: 0px 8px 0px -5px;
  vertical-align: bottom;
  width: 18px;
}
.highlight-tooltip > div div.icon.green {
  background-position: center center;
}
.highlight-tooltip > div div.icon.red {
  background-position: top center;
}
.highlight-tooltip > div div.icon.yellow {
  background-position: bottom center;
}
