.page-404 {
  min-height: 100vh;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.page-404_title{
  font-size: 5rem;
  letter-spacing: .03rem;
  line-height: 72px;
  color: #23232E;
}