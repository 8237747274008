.tags-wrapper {
  line-height: 20px;
  padding-bottom: 18px;

  .box-header {
    background: none;
    font-size: 20px;
    height: 55px;
    line-height: 56px;
    font-family: "Bilo", "Poppins", "Helvetica", "Arial", "sans-serif";
    color: #151a40;
    font-weight: 500;
    margin: 0;
    color: inherit;
    letter-spacing: -0.5px;
    text-rendering: optimizelegibility;
  }

  .tags {
    height: 44px;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    overflow: hidden;

    &.show-all {
      height: auto;
    }

    &.transition {
      -webkit-transition: height 0.1s linear;
      -moz-transition: height 0.1s linear;
      -o-transition: height 0.1s linear;
      transition: height 0.1s linear;
    }
  }

  .all-tags {
    display: inline-block;
    background: #10345d;
    color: white;
    font-weight: bold;
    line-height: 20px;
    height: 20px;
    margin: 0px 20px 2px 20px;
    padding: 0px 22px 0px 20px;
    text-decoration: none;

    &.hide-btn {
      display: none;
    }

    span {
      background: url(https://local.evedo.co/img/web/expand-tags-arrows.gif) no-repeat top right;
      padding: 0px 12px 0px 6px;
    }

    .hide {
      background-position: bottom right;
    }
  }

  form {
    position: relative;

    .ui-menu {
      z-index: 1;
      width: 100%;
      background: white;
      border: 1px solid #d2d2d2;
      border-top: none;
      cursor: default;
      list-style-type: none;
      max-height: 105px;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 5px 0px;
      position: absolute;

      font-family: "Poppins", "HelveticaNeue", "Helvetica", "Arial", "sans-serif";
      font-size: 16px;
      line-height: 22px;
      color: #444;

      li {
        cursor: pointer;
        margin: 0;
        cursor: pointer;
        list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);

        div {
          position: relative;
          padding: 3px 1em 3px 0.4em;

          &:hover {
            margin: -1px;
          }
        }
      }
    }
  }
}
