body {
  background: #f6f5fa !important;
}

.white-bg {
  border-radius: 2px;
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 4px 0px rgb(26 27 27 / 5%);
  -moz-box-shadow: 0px 2px 4px 0px rgba(26, 27, 27, 0.05);
  box-shadow: 0px 2px 4px 0px rgb(26 27 27 / 5%);
  a:hover {
    color: #10345d;
  }
}
.checkout-event-details-wrapper {
  margin-right: 0px;
  padding-right: 35px !important;
}
.type-of-event {
  padding: 2px 12px;
  background: #e4efff;
  border-radius: 32px;
  strong {
    color: black;
  }
  span {
    font-size: 0.735rem;
    text-transform: uppercase;
    font-weight: 600;
    color: #10345d;
  }
}
