.checkout-payment-form {
  max-width: 452px;
  background: #fff;
  @media (min-width: 768px) {
    padding: 0 !important;
  }

  .form-heading {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.01em;
    color: #23232e;
  }

  .short-fields-container {
    .checkout-input-label {
      width: 48.6% !important;
    }
  }

  .checkout-input-container {
    margin-bottom: 24px;
  }

  .terms-container {
    margin-top: 24px;

    .terms-p {
      max-width: 259px;
      font-size: 12px;
      line-height: 18px;
      color: #23232e;

      .term-link {
        color: #611ece;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .go-back-btn {
    font-size: 13px;
    line-height: 19px;
    color: #23232e;
    background: transparent;
    border: none;
    svg {
      margin-right: 2px;
    }
  }
}
