.table-striped tbody tr:nth-child(odd) td,
.table-striped tbody tr:nth-child(odd) th {
  background-color: #f8f8f8;
}
td div strong,
th div {
  font-size: 13px;
  color: #212529;
}
.form-group label {
  font-size: 12px;
}
