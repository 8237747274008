.text-tabs {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 22px;
  color: #8f8e99;
}
.text-tabs.active {
  color: #23232e !important;
}
