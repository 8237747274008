.checkout-tickets-form {
  max-width: 452px;
  @media (min-width: 768px) {
    padding: 0 !important;
    margin-right: 32px;
  }

  .form-heading {
    font-size: 22px;
    line-height: 29px;
    color: #23232e;
  }
}
