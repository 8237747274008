@import "../../../../_definitions";

.when-dropdown {
  right: -300px;
  padding: 22px 29px 36px;
  .dropdown-item {
    border-radius: 55px;
    padding: 0.5rem 1rem;
  }
  .label-m {
    color: #8f8e99;
    white-space: nowrap;
    line-height: 32px;
    border-bottom: 1px solid $grey;
  }
  min-width: 250px;
  .nav-link-normal {
    color: #9da0a7;
    &.current {
      color: $dark;
    }
  }
}
.filter-events {
  .nav-link-normal {
    border-radius: 67px;
    padding: 4px 13px;
    margin: 0px 2px;
    &:hover,
    &.current {
      color: $dark;
      background-color: $grey;
    }
  }
  .category-dropdown {
    top: 100%;
  }
}
.more-categories-dropdown,
.organizers-container {
  max-height: 80vh;
  overflow-y: scroll;
}
@media all and (min-width: 572px) {
  .more-categories-dropdown {
    min-width: 400px;
  }
}
