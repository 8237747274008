.event-host-details {
  border-radius: 2px;
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(26, 27, 27, 0.05);
  -moz-box-shadow: 0px 2px 4px 0px rgba(26, 27, 27, 0.05);
  box-shadow: 0px 2px 4px 0px rgba(26, 27, 27, 0.05);

  h5 {
    font-size: 0.95rem;
    font-weight: 500;
    letter-spacing: -0.3px;

    .host {
      color: #10345d;
    }
  }

  .promoter-image {
    border: 1px solid #f2f2f5;
  }

  p {
    font-size: 1rem;
    color: #787d82;
    line-height: 150%;
    margin-bottom: 1rem;
  }

  p.smaller {
    font-size: 0.9rem;
  }
}
