.categories-wrapper {
  .box-header {
    font-size: 20px;
    height: 55px;
    line-height: 56px;
    font-family: "Bilo", "Poppins", Helvetica, Arial, sans-serif;
    color: #151a40;
    font-weight: 500;
    margin: 0;
    letter-spacing: -0.5px;
    text-rendering: optimizelegibility;
  }

  .categories-list {
    button {
      font-size: 12px;
      color: #888;
      cursor: pointer;
      line-height: 14px;
      padding: 51px 1px 0px 0px;
      margin-bottom: 2px;
      text-align: center;
      background-image: url(https://local.evedo.co/img/web-v2/common/categories.jpg);

      input {
        display: none;
      }
    }

    .unchecked {
      color: #d2d2d2;

      &:hover {
        color: #888;
      }

      &.c1 {
        background-position: 0px 0px;
        @media (min-width: 1024px) {
          background-position: 30px 0px;
        }
      }
      &.c2 {
        background-position: -90px 0px;
        @media (min-width: 1024px) {
          background-position: -60px 0px;
        }
      }
      &.c3 {
        background-position: -180px 0px;
        @media (min-width: 1024px) {
          background-position: -150px 0px;
        }
      }
      &.c4 {
        background-position: -270px 0px;
        @media (min-width: 1024px) {
          background-position: -240px 0px;
        }
      }
      &.c5 {
        background-position: -360px 0px;
        @media (min-width: 1024px) {
          background-position: -330px 0px;
        }
      }
      &.c6 {
        background-position: -450px 0px;
        @media (min-width: 1024px) {
          background-position: -420px 0px;
        }
      }
      &.c7 {
        background-position: -540px 0px;
        @media (min-width: 1024px) {
          background-position: -510px 0px;
        }
      }
      &.c8 {
        background-position: -720px 0px;
        @media (min-width: 1024px) {
          background-position: -690px 0px;
        }
      }
      &.c9 {
        background-position: -810px 0px;
        @media (min-width: 1024px) {
          background-position: -780px 0px;
        }
      }
      &.c10 {
        background-position: -900px 0px;
        @media (min-width: 1024px) {
          background-position: -870px 0px;
        }
      }
      &.c11 {
        background-position: -1080px 0px;
        @media (min-width: 1024px) {
          background-position: -1050px 0px;
        }
      }
      &.c12 {
        background-position: -1170px 0px;
        @media (min-width: 1024px) {
          background-position: -1140px 0px;
        }
      }
      &.c13 {
        background-position: -1260px 0px;
        @media (min-width: 1024px) {
          background-position: -1230px 0px;
        }
      }
    }

    .c1 {
      background-position: 0px -70px;
      @media (min-width: 1024px) {
        background-position: 30px -70px;
      }
    }
    .c2 {
      background-position: -90px -70px;
      @media (min-width: 1024px) {
        background-position: -60px -70px;
      }
    }
    .c3 {
      background-position: -180px -70px;
      @media (min-width: 1024px) {
        background-position: -150px -70px;
      }
    }
    .c4 {
      background-position: -270px -70px;
      @media (min-width: 1024px) {
        background-position: -240px -70px;
      }
    }
    .c5 {
      background-position: -360px -70px;
      @media (min-width: 1024px) {
        background-position: -330px -70px;
      }
    }
    .c6 {
      background-position: -450px -70px;
      @media (min-width: 1024px) {
        background-position: -420px -70px;
      }
    }
    .c7 {
      background-position: -540px -70px;
      @media (min-width: 1024px) {
        background-position: -510px -70px;
      }
    }
    .c8 {
      background-position: -720px -70px;
      @media (min-width: 1024px) {
        background-position: -690px -70px;
      }
    }
    .c9 {
      background-position: -810px -70px;
      @media (min-width: 1024px) {
        background-position: -780px -70px;
      }
    }
    .c10 {
      background-position: -900px -70px;
      @media (min-width: 1024px) {
        background-position: -870px -70px;
      }
    }
    .c11 {
      background-position: -1080px -70px;
      @media (min-width: 1024px) {
        background-position: -1050px -70px;
      }
    }
    .c12 {
      background-position: -1170px -70px;
      @media (min-width: 1024px) {
        background-position: -1140px -70px;
      }
    }
    .c13 {
      background-position: -1260px -70px;
      @media (min-width: 1024px) {
        background-position: -1230px -70px;
      }
    }
  }
}
