section.past-events-widget div.paging {
  height: 30px;
  line-height: 33px;
  button {
    border: none;
    background: #d2d2d2;
    display: inline-block;
    height: 12px;
    margin-right: 2px;
    width: 10px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 50%;
  }
  .active {
    background: #10345d;
  }
}
