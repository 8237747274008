.checkout-event-pricing-ticket {
  background: red;
  p.ticket-name {
    font-weight: 400 !important;
    color: #23232e !important;

    span {
      color: #8f8e99;
    }
  }

  p.ticket-price {
    color: #23232e !important;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }
}
