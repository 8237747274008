.msg-wrapper {
  border-top: 1px solid #e8e8e8;
  line-height: 20px;
  overflow: hidden;
  padding: 10px 0px 9px 20px;

  &:hover {
    time {
      span {
        opacity: 1;
      }
    }
  }

  &.out {
    background: #f8f8f8;
  }

  &.out + &.out {
    border-top: none;
    padding-top: 0px;

    strong {
      display: none;
    }

    p,
    time {
      border-top: 1px solid #f0f0f0;
    }
  }

  p {
    margin: 0px;
  }

  time {
    color: #888;
    font-size: 11px;
    text-align: right;
    width: 105px;

    span {
      opacity: 0;
      padding-right: 2px;
      -webkit-transition: opacity 0.1s linear;
      -moz-transition: opacity 0.1s linear;
      -o-transition: opacity 0.1s linear;
      transition: opacity 0.1s linear;
    }
  }
}
