@import "../../_definitions";

.hero.pricing {
  min-height: auto;
}
h1.page-title {
  width: fit-content !important;
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: Bilo, sans-serif;
  color: #151a40;
  font-size: 4.4rem;
  line-height: 110%;
  font-weight: 700;
  letter-spacing: -1.5px;
  text-transform: capitalize;
  @media screen and (max-width: 991px) {
    font-size: 3rem;
  }
  @media screen and (max-width: 991px) {
    font-size: 2.5rem;
  }
}
h2.page-subtitle {
  font-family: Poppins, sans-serif;
  color: #5b5b5e;
  font-size: 1.2rem;
  line-height: 160%;
  font-weight: 300;
  text-align: center;
  letter-spacing: 0px;
  text-transform: none;
}
.page-title {
  width: 990px !important;
  text-align: center;
  @media screen and (max-width: 991px) {
    margin-bottom: 1rem;
    font-size: 3.4rem;
  }
  @media screen and (max-width: 767px) {
    font-size: 2.6rem;
  }
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.text-block-2 {
  font-size: 1.1rem;
  line-height: 130%;
  text-align: center;
}
.commision-wrap {
  width: 50%;
  padding-top: 2rem;
  padding-right: 2rem;
  padding-left: 2rem;
  @media screen and (max-width: 479px) {
    width: 100%;
    padding-right: 0rem;
    padding-left: 0rem;
  }
}
.comission-box {
  margin-bottom: 26px;
  padding: 2rem;
  border: 2px solid #f347ba;
  border-radius: 8px;
  @media screen and (max-width: 479px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 24px;
  }
  h4 {
    margin-top: 10px;
    margin-bottom: 1rem;
    font-family: Bilo, sans-serif;
    color: #151a40;
    font-size: 1.5rem;
    line-height: 130%;
    font-weight: 700;
    letter-spacing: -0.5px;
    text-transform: none;
  }
}
.heading-2 {
  font-size: 1.2rem !important;
  text-align: center;
}
.commission-percent {
  font-family: Bilo, sans-serif;
  color: #310789;
  font-size: 6rem;
  line-height: 100%;
  font-weight: 800;
  text-align: center;
  @media screen and (max-width: 767px) {
    font-size: 4rem;
  }
}
.text-block {
  text-align: center;
}
.example-wrap {
  margin-top: 3rem;
  padding: 8px;
  border-radius: 80px;
  background-image: -webkit-gradient(linear, left top, right top, from(#ffedee), color-stop(53%, #ffd7f3), to(#f2efff));
  background-image: linear-gradient(90deg, #ffedee, #ffd7f3 53%, #f2efff);
}
.text-block-3 {
  font-size: 13px;
  text-align: center;
}
.label-m {
  margin-bottom: 1rem;
  font-family: Bilo, sans-serif;
  color: #151a40;
  font-size: 0.925rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.box-bordered {
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 4rem;
  border: 1px solid #d6d3df;
  border-radius: 8px;
}
.container-medium.move-up {
  position: relative;
  top: -60px;
  margin-top: -40px;
  margin-bottom: -40px;
}
.left-col {
  width: 60%;
  padding-right: 2rem;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}
.paragraph.paragraph-l {
  font-size: 1.3rem;
  line-height: 155%;
}
.right-col.bg-solid.pale-bg {
  background-color: #fee7f4;
}
.heading-4 {
  margin-top: 1rem;
  margin-bottom: 32px;
  font-family: Bilo, sans-serif;
  color: #151a40;
  font-size: 1.5rem;
  line-height: 130%;
  font-weight: 700;
  letter-spacing: -0.5px;
  text-transform: none;
}
.btn-m.pale {
  background-color: #fcf5ff;
  font-size: 1.1rem;
}
.right-col {
  width: 40%;
  padding-right: 1rem;
  padding-left: 1rem;
  text-align: center;
  @media screen and (max-width: 767px) {
    width: 100%;
    padding-right: 4rem;
    padding-left: 4rem;
  }
}
.label-comingsoon.move-up {
  margin-top: -30px;
}
.container-small.centered {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.div-block {
  text-align: left;
}
.grid {
  margin-top: 1rem;
  margin-bottom: 2rem;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  @media screen and (max-width: 767px) {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}
.container-small {
  display: flex;
  max-width: 50rem;
  margin-right: auto;
  margin-left: auto;
}
.btn-s.secondary.left-m {
  margin-left: 16px;
  @media screen and (max-width: 767px) {
    margin-left: 0px;
  }
}
.btn-s.secondary {
  background-color: #863ee2;
  color: #fff;
}
.btn-s {
  padding: 0.4rem 2rem;
  border-radius: 40px;
  color: #450ab8;
  font-size: 1rem;
  line-height: 160%;
  text-decoration: none;
}
.container-main.pricing-top {
  @media screen and (max-width: 767px) {
    padding-bottom: 1rem;
  }
}
.container-main.mb-4 {
  @media screen and (max-width: 991px) {
    padding-top: 1rem;
    margin-top: 0;
  }
}
