.searchbox {
  height: 4.5rem;
  position: absolute !important;
  max-width: 80% !important;
  flex-direction: row;
  flex-wrap: nowrap !important;
  bottom: -50%;
  left: 0;
  right: 0;
  border-radius: 48px;
  box-shadow: 0px 16px 44px rgba(0, 0, 0, 0.08);
  div,
  button {
    height: 4.5rem;
  }
  @media all and (max-width: 768px) {
    flex-direction: column;
    max-width: 400px;
    position: static;
    height: auto;
    .bl-grey {
      border: 0;
      border-top: 1px solid #d4d4d4;
      border-bottom: 1px solid #d4d4d4;
      margin-bottom: 1rem;
    }
    .btn {
      border-radius: 48px !important;
    }
  }
  .search-input {
    min-width: 200px;
    // max-width: 80%;
  }
  // @media all and (min-width: 768px) and (max-width: 992px) {
  //   .search-input {
  //     min-width: 200px;
  //     max-width: 80%;
  //   }
  // }
}

.searchbox .btn {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  border-radius: 48px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  height: 100%;
}
.bg-dark-blue {
  background-color: #151a40;
}

.header-title-search {
  font-family: "Bilo", "Poppins", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 3rem;
}
.search-page-content .nav-tabs {
  border-bottom: none;
}
.search-page-content .nav-link {
  padding: 0.5rem 0rem;
  &::after {
    content: "";
    position: absolute;
    transition: 0.3s ease;
    left: 0;
    width: 0;
    top: 100%;
    height: 2px;
    background-color: #f347ba;
  }
  &.active {
    &::after {
      width: 100%;
    }
  }
}
.search-page-content .nav-link:hover {
  border-color: transparent;
  &::after {
    transition: 0.3s ease;
    width: 100%;
  }
}
.search-page-content .nav-tabs .nav-item {
  margin-right: 1.875rem;
}
.status {
  font-family: "Bilo", "Poppins", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.688rem;
  line-height: 22px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
}

.list-group-item {
  border-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 1rem 0rem;
}

.list-group-item .featured-text {
  font-size: 14px;
}
.card.border-radius-64 {
  border-radius: 64px;
}
/* Filter Card Styles */
.filter-card {
  border-radius: 3px;
  border: 1px solid #f1eef5;
  padding: 1rem;
  background-color: #f6f5fa;
}
.filter-title {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 16px;
  color: #49494f;
}
.filter-category-list .list-group-item {
  border-bottom: none;
}
.dropdown-toggle {
  font-size: 0.875rem;
  font-weight: 500;
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
}
.bl-grey {
  border-left: 1px solid #f1eef5;
}
.search-icon-action {
  width: 30px;
}
.input-tag {
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  padding: 5px 5px 0;
}
#tag-suggestions-trigger {
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.filter-category-list {
  .list-group-item {
    cursor: pointer;
  }
}
