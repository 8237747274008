.event-details {
  .white-bg {
    border-radius: 2px;
    background-color: #fff;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(26, 27, 27, 0.05);
    -moz-box-shadow: 0px 2px 4px 0px rgba(26, 27, 27, 0.05);
    box-shadow: 0px 2px 4px 0px rgba(26, 27, 27, 0.05);

    h4 {
      font-size: 1.4rem;
      font-weight: 400;
      letter-spacing: -0.6px;
    }

    p {
      font-size: 1rem;
      color: #787d82;
      line-height: 150%;
      margin-bottom: 1rem;
    }

    .section-heading {
      font-size: 0.735rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 500;
    }

    .meta {
      font-size: 0.835rem;
      letter-spacing: -0.2px;
      font-weight: 400;
      color: rgba(120, 125, 130, 0.8);
      line-height: 150%;

      &.color {
        color: #294b71;
      }
    }

    .link {
      color: #2b7fe5;
      font-size: 0.935rem;
      font-weight: 600;
      letter-spacing: 0.1px;

      &:hover {
        color: #10345d;
      }

      &.sm {
        font-size: 0.835rem;
      }
    }
  }
}
