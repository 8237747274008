@import "../../../definitions";

.modal-body {
  max-width: 750px;
  margin: auto !important;
  overflow: visible;
  padding-left: 50px !important;
  -webkit-box-shadow: inset 0px 1px 2px 0px #e2e2e2;
  -moz-box-shadow: inset 0px 1px 2px 0px #e2e2e2;
  box-shadow: inset 0px 1px 2px 0px #e2e2e2;

  @media screen and (max-width: 600px) {
    .wrapper-form {
      flex-direction: column !important;
      max-width: 255px;
      margin: auto !important;
    }
  }

  legend + * {
    clear: left;
  }
  fieldset {
    float: left;
    margin-right: 40px;
    width: 245px;
    legend {
      display: block;
      font-size: 11px;
      font-weight: bold;
      height: 18px;
      line-height: 6px;
      margin: 0px;
      padding: 8px 0px 0px 0px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
    }
  }
  fieldset {
    div input {
      background-color: transparent;
      border: none;
      font-size: 14px;
      height: 35px;
      margin: 0px;
      padding: 0px 10px;
      width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    div a.hint {
      margin: 11px 8px 0px 0px;
      float: right;
    }
    div {
      background: #ffffff;
      border-bottom: 1px solid #e8e8e8;
      border-left: 1px solid #d2d2d2;
      border-right: 1px solid #d2d2d2;
      height: 35px;
    }
    .name {
      border-radius: 2px 2px 0px 0px;
      border-top: 1px solid #d2d2d2;
      box-shadow: inset 0px 1px 0px 0px rgb(0 0 0 / 2%);
    }
    div label {
      background-color: transparent;
      border: none;
      font-size: 14px;
      height: 35px;
      margin: 0px;
      padding: 0px 10px;
      width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
  div a.hint + input {
    padding: 0px 5px 0px 10px;
    width: 220px;
  }
  a.hint {
    background-image: url(#{$domain}/img/web-v2/common/hint-question.png);
  }
  a.hint {
    background: #d2d2d2 url(#{$domain}/img/web-v2/common/hint-question.png);
    background-size: 13px 13px;
    display: inline-block;
    height: 13px;
    vertical-align: -2px;
    width: 13px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
  }
  fieldset.company-details label span {
    font-size: 12px;
    font-weight: bold;
    margin-top: 11px;
  }
  .vat {
    border-bottom-color: #d2d2d2;
    border-radius: 0px 0px 2px 2px;
    p {
      font-size: 12px;
      font-weight: bold;
      margin-top: 11px;
    }
  }
  label input[type="checkbox"].custom,
  label input[type="radio"].custom {
    display: none;
  }
  label input[type="checkbox"].custom + span {
    background: url(#{$domain}/img/web-v2/common/custom-checkbox.png);
    background-size: 14px 26px;
    cursor: pointer;
    display: inline-block;
    height: 13px;
    margin-right: 4px;
    vertical-align: -2px;
    width: 14px;
  }
  fieldset.company-details label + input {
    display: none;
    float: left;
    padding-left: 0px !important;
    width: 205px !important;
  }
  .company-vat {
    width: 15px;
  }
  fieldset > div select {
    background-color: transparent;
    border: none;
    font-size: 14px;
    height: 35px;
    margin: 0px;
    padding: 0px 10px;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}
