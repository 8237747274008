.general-settings-form-wrapper {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", "Arial", "sans-serif";
  font-size: 16px;
  line-height: 22px;
  color: #444;

  form,
  .wallet-wrapper {
    background-color: #ffffff;
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    margin-bottom: 18px;

    .container-fluid {
      h2 {
        font-size: 16px;
        line-height: 16px;
        padding-bottom: 8px;
        font-family: "Bilo", "Poppins", "Helvetica", "Arial", "sans-serif";
        font-weight: 500;
        margin: 0;
        color: inherit;
        letter-spacing: -0.5px;
        text-rendering: optimizelegibility;
      }

      input {
        background-color: #ffffff;
        border: 1px solid #d2d2d2;
      }

      .message {
        background: url(https://local.evedo.co/img/web-v2/icons/warning-gray.jpg) no-repeat -10px 7px;
        background-size: 26px 16px;
        color: #e62e83;
        height: 30px;
        line-height: 32px;
        margin-top: 10px;
        padding: 0px 10px 0px 25px;

        &.success {
          background-image: url(https://local.evedo.co/img/web-v2/icons/success.jpg);
          background-size: 26px 16px;
          color: #4fb260;
        }
      }

      .btn-primary {
        display: inline-block;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        padding: 0.735rem 1rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 2px;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
          box-shadow 0.15s ease-in-out;
        background-color: #e62e83;
        border: 0;
        color: #fff;

        &:hover {
          background: #0e2c4f;
        }
      }
    }
  }
}
