.event-rsvp {
  .white-bg {
    border-radius: 2px;
    background-color: #fff;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(26, 27, 27, 0.05);
    -moz-box-shadow: 0px 2px 4px 0px rgba(26, 27, 27, 0.05);
    box-shadow: 0px 2px 4px 0px rgba(26, 27, 27, 0.05);

    h5 {
      font-size: 0.95rem;
      font-weight: 500;
      letter-spacing: -0.3px;

      .link {
        font-size: 0.835rem;
        color: #2b7fe5;
        font-size: 0.935rem;
        font-weight: 600;
        letter-spacing: 0.1px;

        &:hover {
          color: #10345d;
        }
      }
    }

    .rsvp-container {
      .rsvp-btn {
        width: 100%;
        height: 50px;
        border-radius: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        transition: 0.25s all;
        margin-right: -1px;
        background-size: 20px;
        background-color: #fff;
        border: 1px solid rgba(152, 183, 220, 0.2);
        background-repeat: no-repeat;
        background-position: center center;
        position: relative;

        &.marker-green {
          color: #12982b;

          &.selected,
          &:hover {
            background: #12982b;
            color: white;
          }

          &:hover {
            .marker-title {
              display: block;
              color: #12982b;
            }
          }
        }

        &.marker-yellow {
          color: #f6a131;

          &.selected,
          &:hover {
            background: #f6a131;
            color: white;
          }

          &:hover {
            .marker-title {
              display: block;
              color: #f6a131;
            }
          }
        }

        &.marker-pink {
          color: #e62e83;

          &.selected,
          &:hover {
            background: #e62e83;
            color: white;
          }

          &:hover {
            .marker-title {
              display: block;
              color: #e62e83;
            }
          }
        }

        &.marker-blue {
          color: #f15151;

          &.selected,
          &:hover {
            background: #10345d;
            color: white;
          }

          &:hover {
            .marker-title {
              display: block;
              color: #10345d;
            }
          }
        }

        &:first-of-type {
          border-radius: 3px 0 0 3px;
        }

        &:last-of-type {
          border-radius: 0 3px 3px 0;
        }

        .marker-title {
          position: absolute;
          top: 50px;
          font-size: 14px;
          overflow: visible;
          white-space: nowrap;
          display: none;
        }
      }
    }
  }
}
