@import "../../../_definitions";
@import "./Welcome.scss";

.categories-list label {
  font-size: 15px;
  color: #888;
  cursor: pointer;
  line-height: 10px;
  padding: 51px 1px 0px 0px !important;
  margin-bottom: 2px;
  text-align: center;
}
section.profile-setup div.mask.steps {
  height: fit-content;
}
.categories-list label {
  background-image: url(https://local.evedo.co/img/web-v2/common/categories.jpg);
  .categories-list {
    label {
      color: $mediumGray;
      cursor: pointer;
      line-height: 10px;
      padding: 51px 1px 0px 0px !important;
      margin-bottom: 2px;
      text-align: center;
      &:hover {
        color: $mediumGray !important;
      }
      &.disabled {
        cursor: default;
      }
      &.disabled:hover {
        color: $mediumGray !important;
      }
      &.disabled.unchecked:hover {
        color: $lightGray1 !important;
      }
      input {
        display: none;
      }
    }
    .empty {
      background: $white;
      float: left;
      height: 70px;
      margin: 1px 1px 0px 0px;
      width: 90px;
    }
  }
}
.categories-list label {
  &.c1 {
    background-position: -10px -70px;
  }
  &.c2 {
    background-position: -100px -70px;
  }
  &.c3 {
    background-position: -190px -70px;
  }
  &.c4 {
    background-position: -275px -70px;
  }
  &.c5 {
    background-position: -375px -70px;
  }
  &.c6 {
    background-position: -460px -70px;
  }
  &.c7 {
    background-position: -555px -70px;
  }
  &.c8 {
    background-position: -650px -70px;
  }
  &.c9 {
    background-position: -735px -70px;
  }
  &.c10 {
    background-position: -835px -70px;
  }
  &.c11 {
    background-position: -895px -70px;
  }
  &.c12 {
    background-position: -1030px -70px;
  }
  &.c13 {
    background-position: -1096px -70px;
  }
  &.c14 {
    background-position: -1195px -70px;
  }
  &.c15 {
    background-position: -1275px -70px;
  }

  &.unchecked {
    color: $lightGray1;
    &.c1 {
      background-position: -10px 0;
    }
    &.c2 {
      background-position: -100px 0;
    }
    &.c3 {
      background-position: -190px 0;
    }
    &.c4 {
      background-position: -275px 0;
    }
    &.c5 {
      background-position: -375px 0;
    }
    &.c6 {
      background-position: -460px 0;
    }
    &.c7 {
      background-position: -555px 0;
    }
    &.c8 {
      background-position: -700px 0;
    }
    &.c9 {
      background-position: -735px 0;
    }
    &.c10 {
      background-position: -835px 0;
    }
    &.c11 {
      background-position: -895px 0;
    }
    &.c12 {
      background-position: -1030px 0;
    }
    &.c13 {
      background-position: -1096px 0;
    }
    &.c14 {
      background-position: -1195px 0;
    }
    &.c15 {
      background-position: -1275px 0;
    }
  }
}
.categories-list label input {
  display: none;
}
input[type="checkbox"] {
  width: auto;
  margin: 3px 0;
  line-height: normal;
  cursor: pointer;
}
div.categories-list {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 36px;
  gap: 36px;
  padding: 35px 15px !important;
  max-width: 800px;
  width: 90vw;
}
