.favourite-promoter-card {
  color: #888;
  line-height: 16px;
  padding: 0px 20px 16px 0px;
  text-align: center;

  .mask {
    height: 122px;
    width: 122px;
    display: block;
    -webkit-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    img {
      height: inherit;
      width: inherit;
      object-fit: cover;
    }
  }

  .title,
  span {
    width: 122px;
  }

  .title {
    color: #444;
    display: block;
    font-weight: bold;
    overflow: hidden;
    padding-top: 7px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 20px;
  }
}
