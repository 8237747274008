.activity-card {
  border-bottom: 1px solid #e2e2e2;
  min-height: 125px;
  padding: 0px 20px 0px 145px;
  position: relative;

  .mask {
    height: 85px;
    left: 20px;
    position: absolute;
    top: 20px;
    width: 105px;
    overflow: hidden;

    img {
      min-height: 85px;
      width: 105px;
      object-fit: cover;
    }
  }

  button {
    border: none;
    border-top: 1px solid #e2e2e2;
    background: white;
    color: #888;
    display: block;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    position: relative;
    text-decoration: none;
    text-align: left;
    :hover {
      background: #fafafa;
    }
  }

  span {
    color: #888;
    display: block;
    padding-top: 24px;
  }

  .title {
    color: #444;
    display: block;
    font-family: "Georgia", "Times New Roman", "serif";
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  .marker {
    display: inline-block;
    font-weight: bold;
    height: 21px;
    margin-top: 5px;
    padding: 0px 10px;
    text-transform: uppercase;

    &.marker-yellow {
      color: #f6a131;

      &:hover {
        color: white;
        background: #f6a131;
      }
    }

    &.marker-green {
      color: #12982b;

      &:hover {
        color: white;
        background: #12982b;
      }
    }

    &.marker-pink {
      color: #e62e83;

      &:hover {
        color: white;
        background: #e62e83;
      }
    }
  }
}
