.checkout-discount-form {
  background: #ffffff;
  box-shadow: 0px 16px 44px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  max-width: 376px;
  position: relative;

  .close-btn {
    position: absolute;
    top: 13px;
    right: 13px;

    i {
      color: #abaab6;
    }
  }

  .form-heading {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #23232e;
  }

  .checkout-input-container {
    .checkout-input {
      padding: 15px 12px;
      height: 42px;
    }
  }

  .invalid-p {
    font-weight: normal;
    font-size: 13px;
    line-height: 12px;
    letter-spacing: 0.01em;
    color: #49494f;
  }

  .checkout-btn {
    padding: 8px 0 !important;
  }
}
