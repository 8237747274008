.search-friends {
  .search-friends-input-container {
    height: 50px;

    &.hidden {
      height: 20px !important;

      .search-friends-input {
        display: none;
      }
    }

    .search-friends-input {
      background-color: #ffffff;
      border: 1px solid #d2d2d2;
      outline: none;
      padding: 1px 2px;
      font-family: "Poppins", "HelveticaNeue", "Helvetica", "Arial", "sans-serif";
      font-size: 16px;
      line-height: 22px;
      color: #444;

      &:focus {
        &::placeholder {
          opacity: 0.6;
        }
        &::-moz-placeholder {
          opacity: 0.6;
        }
        &::-webkit-input-placeholder {
          opacity: 0.6;
        }
      }
    }
  }
}
