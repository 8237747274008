.search-friends-wrapper {
  background: white;
  margin: 0px;
  padding: 0px 10px;
  position: absolute;
  z-index: 1;
  -webkit-transition: box-shadow 0.2s linear;
  -moz-transition: box-shadow 0.2s linear;
  -o-transition: box-shadow 0.2s linear;
  transition: box-shadow 0.2s linear;

  .search-friends-input {
    font-family: "Poppins", "HelveticaNeue", "Helvetica", "Arial", "sans-serif";
    font-size: 16px;
    line-height: 22px;
    color: #444;
    width: 198px;
    padding: 12px;
    margin-top: 10px;
    background: #fff;
    border: 1px solid #d2d2d2;
    outline: none;

    &:focus {
      &::placeholder {
        opacity: 0.6;
      }
      &::-moz-placeholder {
        opacity: 0.6;
      }
      &::-webkit-input-placeholder {
        opacity: 0.6;
      }
    }
  }

  .search-results {
    width: 198px;
    background: white;
    border: 1px solid #d2d2d2;
    border-top: none;
    cursor: default;
    list-style-type: none;
    max-height: 105px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 5px 0px;
    position: absolute;
    -webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    list-style: none;
    margin: 0;
    display: block;
    z-index: 100;

    .user-li {
      margin: 0;
      cursor: pointer;
      list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);

      .user-names {
        position: relative;
        padding: 3px 1em 3px 0.4em;
        font-family: "Poppins", "HelveticaNeue", "Helvetica", "Arial", "sans-serif";
        font-size: 16px;
        line-height: 22px;
        color: #444;

        &:hover {
          margin: -1px;
        }
      }
    }
  }
}
