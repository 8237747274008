.event-tickets-responsive {
  border-radius: 3px;
  transition: 0.3s all;
  background-color: #fff;
  border: 1px solid #fff;
  border-bottom: 1px solid rgba(152, 183, 220, 0.3);

  p {
    font-size: 1rem;
    color: #787d82;
    line-height: 150%;
  }

  .maintitle {
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -0.5px;
    color: #10345d;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 1.5rem;
  }

  .subtitle {
    line-height: 125%;
    font-size: 1rem;
    color: rgba(120, 125, 130, 0.8);
    font-family: "Open Sans";
    font-weight: 400;
    letter-spacing: 0;
  }

  .type-of-event {
    display: inline-block;
    padding: 2px 12px;
    background: #e4efff;
    border-radius: 32px;

    i {
      display: inline-block;
      color: #434343;
    }

    span {
      font-size: 0.735rem;
      text-transform: uppercase;
      font-weight: 600;
      color: #10345d;
    }
  }

  .priceline {
    font-size: 0.835rem;
    color: #787d82;
    line-height: 150%;
    margin-bottom: 1rem;

    img {
      display: inline-block;
    }

    .date-highlight {
      font-size: 1rem;
      text-transform: uppercase;
      color: #10345d;
      font-weight: 600;
      display: inline-block;
      border-right: 1px solid rgba(152, 183, 220, 0.3);
      padding: 0px;
      margin-left: 5px;
    }
  }

  .btn-primary:hover {
    background-color: #d60d6a;
    border-color: #d60d6a;
  }
}
