section.legal {
  margin-top: 40px;
  padding-left: 230px;
  position: relative;
  width: auto;
  aside {
    display: block;
    bottom: 40px;
    left: 0;
    position: absolute;
    top: 0;
    width: 230px;

    div.sidebar-menu {
      border-bottom: 5px solid #111;
      border-top: 5px solid #111;
      top: 90px;
      width: 230px;
      background: #10345d;
      color: #ffffff;
      margin-bottom: 20px;
    }
  }
}
.sticky {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 0px;
}
.sidebar-menu a.indent {
  border-bottom: none;
}
.sidebar-menu a {
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  color: #d2d2d2;
  display: block;
  font-weight: bold;
  line-height: 30px;
  padding: 10px 40px;
  position: relative;
  text-decoration: none;
}
.sidebar-menu a.active {
  background: #10345d;
  color: white;
}
.sidebar-menu a.active:after {
  content: "";
  display: block !important;
  position: absolute;
  right: -10px;
  top: 0px;
  border-color: transparent transparent transparent #10345d;
  border-style: solid;
  border-width: 15px 0px 15px 10px;
  height: 0px;
  width: 0px;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  transform: rotate(360deg);
}
.sidebar-menu a.indent:after {
  background: rgba(255, 255, 255, 0.08);
  content: "";
  display: block;
  height: 1px;
  margin-left: 0px;
}

.box {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 12%);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
section.legal div.box p {
  font-size: inherit;
  line-height: inherit;
}

section.legal aside div.sidebar-menu {
  border-bottom: 5px solid #111;
  border-top: 5px solid #111;
  top: 90px;
  width: 230px;
}
section.legal div.box {
  float: left;
  font-size: 13px;
  line-height: 18px;
  margin: 0 0 40px 20px;
  padding: 20px 30px 30px 30px; /* max-width: 100%; */
  width: 100%;
}
section.legal div.box h2,
section.legal div.box h3,
section.legal div.box h4,
section.legal div.box p,
section.legal div.box ol,
section.legal div.box ul {
  font-family: "HelveticaNeue", "Helvetica", Arial, sans-serif;
  margin-bottom: 0;
  margin-top: 10px;
}
section.legal div.box h2 {
  font-family: "HelveticaNeueLight", "HelveticaNeue-Light", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  margin-top: 0;
}
section.legal div.box h2 + h4,
section.legal div.box h3 + h4 {
  margin-bottom: 20px;
  margin-top: 0;
}
section.legal div.box h3 {
  margin-top: 20px;
}
section.legal div.box h4 {
  font-weight: bold;
}
section.legal div.box ol ol,
section.legal div.box ul ul,
section.legal div.box ul ol,
section.legal div.box ol ul,
section.legal div.box strong + p {
  margin-top: 0;
}
section.legal div.box ol.spacing > li,
section.legal div.box ul.spacing > li {
  margin-top: 10px;
}
section.legal div.box ol.spacing > li:first-child,
section.legal div.box ul.spacing > li:first-child {
  margin-top: 0;
}
section.legal div.box p {
  font-size: inherit;
  line-height: inherit;
}
section.legal div.box p.big-text {
  font-size: 16px;
  line-height: 22px;
}
@media (max-width: 767px) {
  section.legal {
    padding: 0;
  }
  section.legal aside {
    position: static;
    overflow: hidden;
    margin: 0 auto;
  }
  section.legal div.box {
    margin: 0;
  }
}
.border-table {
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border: 1px solid #a9a9a9;
    padding: 6px;
  }
}
