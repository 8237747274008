.checkout-event-details-wrapper {
  max-width: 383px;
  @media (min-width: 768px) {
    margin-right: 62px;
  }

  .checkout-event-details-container {
    background: #ffffff;
    box-shadow: inset 0px -1px 0px #eceaf0;
    border-radius: 4px;
    border: 1px solid #d6d3df;
    padding: 0;

    *:last-child {
      border: 0;
    }

    p {
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      color: #49494f;
    }

    .title {
      font-family: "Poppins";
      line-height: 21px;
      font-size: 14px;
      letter-spacing: normal;
      color: #201f22;
    }
  }
}
