.tooltip-event {
  button {
    display: block;
    height: 45px;
    padding: 5px 10px;
    width: 100%;
    font-family: "Poppins", "HelveticaNeue", "Helvetica", "Arial", "sans-serif";
    font-size: 16px;
    line-height: 17px;

    &:hover {
      strong {
        color: #10345d;
      }
    }

    img {
      height: 40px;
      float: left;
      margin-right: 10px;
      width: 40px;
    }

    strong {
      color: #444;
      display: block;
      height: 17px;
      margin-top: 2px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
    }

    span {
      color: #888 !important;
      display: block;
      font-weight: normal !important;
      overflow: hidden;
      text-align: left !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 15px;
    }
  }
}
