.event-details-tickets {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
    top: 20px;

    .cta-box {
      border-radius: 3px;
      transition: 0.3s all;
      background-color: #fff;
      border: 1px solid #fff;

      &:hover {
        background-color: #fff;
        -webkit-box-shadow: 0px 0px 12px 0px rgba(26, 27, 27, 0.1);
        -moz-box-shadow: 0px 0px 12px 0px rgba(26, 27, 27, 0.1);
        box-shadow: 0px 0px 12px 0px rgba(26, 27, 27, 0.1);
      }

      .ticket {
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);

        .name {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          font-size: 1.5rem;
          font-weight: 600;
          line-height: 130%;
          letter-spacing: -0.5px;
          color: #10345d;
        }

        .subtitle {
          font-size: 1rem;
          color: rgba(120, 125, 130, 0.8);
          font-family: "Open Sans";
          font-weight: 400;
          letter-spacing: 0;
          line-height: 125%;
        }

        .pt-4 {
          .price-line {
            font-size: 0.835rem;
            color: #608cbe;
            line-height: 150%;
            margin-bottom: 1rem;

            img {
              display: inline-block;
            }

            .date-highlight {
              font-size: 1rem;
              text-transform: uppercase;
              color: #10345d;
              font-weight: 600;
              display: inline-block;
              border-right: 1px solid rgba(152, 183, 220, 0.3);
              padding: 0px;
              margin-left: 5px;
            }
          }

          h3 {
            color: #b2b2b2;
            font-family: "Maven Pro", "Arial", "sans-serif";
            font-size: 12px;
            line-height: 18px;
            padding: 11px 0px;
            font-weight: 600;
            letter-spacing: -0.3px;
          }

          .btn-primary:hover {
            background-color: #d60d6a;
            border-color: #d60d6a;
          }
        }
      }
    }

    .type-of-event {
      display: inline-block;
      padding: 2px 12px;
      background: #e4efff;
      border-radius: 32px;

      img {
        display: inline-block;
      }

      span {
        color: #10345d;
        font-size: 0.735rem;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }
}
