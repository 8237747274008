@import "../../../definitions";

.multi-use-code-form {
  width: 80vw;
  margin: auto !important;
  .time {
    margin-left: 1px !important;
  }
  @media screen and (max-width: 1336px) {
    width: 90vw;
  }
  @media screen and (max-width: 1158px) {
    width: 95vw;
    .time {
      width: 95px;
    }
    .input-group.period {
      width: 100px;
    }
  }
}
.input-group {
  border: 1px solid black;
  input {
    border: none;
  }
  .form-select {
    border: none;
    width: 10%;
    // background: white;
  }
}
.event-time {
  background: #005c11;
  display: inline-block;
  height: 15px;
  margin: 4px 6px 0px 1px;
  vertical-align: -2px;
  width: 15px;
}

div.modal-body {
  // background-color: white;
  // padding: inherit;
  // overflow: hidden;
  // width: 800px;

  .wrapper {
    // height: 450px;
    width: 1600px;
    overflow: hidden;
    @include transformTransition(0.4s, ease-in-out);

    .slide {
      float: left;
      overflow-y: scroll;
      position: relative;
      width: 800px;
    }
  }
}
.input-padding {
  padding-left: 6px;
  padding-right: 0px;
}
.button-container {
  width: fit-content;
  margin: auto !important;
  margin-top: 50px !important;
}
.btn-create {
  background: #12982b;
  font-weight: bold;
  color: #ffffff;
  display: block;
  float: left;
  width: 115px;
  padding: 0.735rem 1rem;
  border-radius: 2px;
}
.btn-cancel {
  background: #b2b2b2;
  color: #ffffff;
  display: block;
  float: left;
  width: 115px;
  border-radius: 2px;
  padding: 0.735rem 1rem;
}
.date {
  width: 95px;
}
.time {
  padding-left: 5px;
  width: 80px;
}

.discount-codes {
  .add {
    display: block;
    float: left;
    height: 30px;
    position: relative;
    width: 30px;
    border: none;
  }
  .add::after {
    background-color: #12982b;
    background-image: url(https://local.evedo.co/img/web-v2/icons/add-white.gif);
    background-size: 12px 12px;
  }
  .remove {
    display: block;
    float: left;
    height: 30px;
    position: relative;
    width: 30px;
    border: none;
  }
  button::after {
    background: #d2d2d2 url(https://local.evedo.co/img/web-v2/icons/remove-medium-white.gif) no-repeat center center;
    background-size: 10px 10px;
    content: "";
    display: block;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    margin: auto;
    margin-top: 6px;
  }
}
.btn-multi-use {
  width: 50px;
}
form {
  max-width: 750px;
}

.form-check-input{
  width:16px !important;
  height: 16px !important;
}

.disable{
  opacity: 0.4;
}

.disable-cursor:hover{
  cursor: not-allowed !important;
}