.about-title {
  font-size: 2rem;
}
.nearby-card {
  transition: 0.3s ease-in;
}
.nearby-card:hover {
  .card-date {
    background-color: #f347ba;
    transition: 0.3s ease-in;
  }
  .overlay {
    opacity: 1;
    transition: 0.3s ease-in;
  }
  .card-bg {
    background-size: 105%;
    transition: 0.3s ease-in;
  }
}
.card-type {
  color: #9da0a7;
  line-height: 14px;
  font-weight: 600;
}
.notable-promoters {
  border-radius: 32px 0 0 32px;
  .slick-list {
    padding: 0 10vw 0 0 !important;
  }
  .slick-track {
    padding: 32px 0;
  }
  .slick-slide {
    padding-right: 0;
    padding-left: 21px;
  }
  .slick-slider {
    margin-left: -21px;
  }
}
