.similar-events {
  .events-widget {
    border-radius: 2px;
    background-color: #fff;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(26, 27, 27, 0.05);
    -moz-box-shadow: 0px 2px 4px 0px rgba(26, 27, 27, 0.05);
    box-shadow: 0px 2px 4px 0px rgba(26, 27, 27, 0.05);

    .swiper {
      .swiper-button-prev,
      .swiper-button-next {
        width: 12px;
        color: #10345d;

        &::after {
          font-size: 20px;
          font-weight: bold;
        }
      }

      .swiper-button-prev {
        left: 0;
      }

      .swiper-button-next {
        right: 0;
      }

      .swiper-pagination {
        bottom: 0;

        .swiper-pagination-bullet {
          background: #10345d;
          height: 10px;
          width: 10px;
        }
      }
    }
  }
}
