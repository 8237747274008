.similar-event-card {
  .title-container {
    text-indent: 0px;
    overflow: hidden;
    white-space: nowrap;
    clear: both;

    &:hover {
      white-space: pre-wrap;
    }

    h3 {
      transition: all 1.5s linear;
      font-size: 1.44rem;
      font-weight: 600;
      letter-spacing: -0.3px;
      color: #151a40;
    }
  }

  .card-content {
    height: auto;
    padding-top: 10px;
    overflow: hidden;
    position: relative;

    .mask {
      display: block;
      width: 90%;
      margin: auto;
      position: relative;

      img {
        width: 100%;
        max-height: 250px;
      }

      .category {
        background: #10345d;
        color: white;
        display: block;
        height: 19px;
        left: 10px;
        line-height: 20px;
        padding: 0px 10px;
        position: absolute;
        text-decoration: none;
        text-transform: uppercase;
        bottom: 0px;

        &:hover {
          transition: 0.3s all;
          background: #e62e83;
        }
      }
    }

    span {
      color: #888;
      display: block;
      float: left;
      overflow: hidden;
      padding: 3px 0px 4px 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 135px;
    }

    time {
      color: #888;
      float: right;
      padding: 3px 10px 0px 0px;
      margin-bottom: 30px;
    }
  }
}
