@import "../../_definitions";

body {
  background-color: white !important;
}
@media (max-width: 1300px) {
  .container-my-page {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.hero.why-plentix {
  max-width: 100%;
  margin-bottom: 4rem;
  // background-image: url(#{$domain}/img/organizers/buddies-at-the-summer-festival-RW2DB23.jpg);
  background-position: 100% 50%;
  background-size: 60%;
  background-repeat: no-repeat;
  @media screen and (max-width: 991px) {
    min-height: auto;
    padding-top: 2rem;
    padding-bottom: 30rem;
    padding-left: 1rem;
    background-position: 50% 100%;
    background-size: auto 28rem;
  }
}
.hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1720px;
  min-height: 600px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.hero-contrnt {
  width: 50%;
  @media (max-width: 991px) {
    width: 100% !important;
  }
}
.div-block-3 {
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  @media (max-width: 1300px) {
    justify-content: flex-start;
  }
}
.hero-text.features {
  width: 775px;
  text-align: left;
}
.hero-text {
  max-width: 62rem;
  margin-right: auto;
  margin-left: auto;
  h1 {
    width: 700px;
    margin-top: 0px;
    margin-bottom: 10px;
    font-family: Bilo, sans-serif;
    color: #151a40;
    font-size: 4.6rem;
    line-height: 110%;
    font-weight: 700;
    letter-spacing: -1.5px;
    text-transform: capitalize;
  }
}
.label-l.highlight-1 {
  color: #c374f7;
}
.left-aligned {
  display: inline-block;
  text-align: left !important;
  @media screen and (max-width: 767px) {
    h4 {
      margin-top: 6px;
      font-size: 1.15rem;
    }
  }
}
.page-subtitle.left-aligned {
  color: #5b5b5e;
  text-transform: none;
}
.page-subtitle {
  font-family: Poppins, sans-serif;
  color: #5b5b5e;
  font-size: 1.2rem;
  line-height: 160%;
  font-weight: 300;
  text-align: center;
  letter-spacing: 0px;
  text-transform: none;
}
.cta-block {
  display: flex;
  margin-top: 0rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.btn-main {
  margin-top: 0rem;
  padding: 1rem 3rem;
  border-radius: 40px;
  background-color: #f347ba;
  color: #fff;
  font-size: 1.2rem;
  text-decoration: none;
}
.w-inline-block {
  max-width: 100%;
  display: inline-block;
}
.or-text {
  margin-right: 8px;
  margin-left: 8px;
  color: #5b5b5e;
  font-size: 1rem;
  line-height: 130%;
}
.link-with-arrow {
  max-width: fit-content;
  display: flex;
  align-items: center;
  color: #f347ba;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0px;
  text-decoration: none;
  img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
  }
}
.label-l {
  font-family: Bilo, sans-serif;
  font-size: 1rem;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.link-arrow {
  width: 12px;
  margin-left: 2px;
  -webkit-transform: translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
  -moz-transform: translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
  -ms-transform: translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
  transform: translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
  opacity: 0;
}

.container-main {
  max-width: 1220px;
  margin-top: 2rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
  h2 {
    margin-top: 10px;
    margin-bottom: 2rem;
    font-family: Bilo, sans-serif;
    color: #151a40;
    font-size: 3.4rem;
    line-height: 110%;
    font-weight: 700;
    letter-spacing: -0.5px;
    text-transform: capitalize;
  }
}

.w-layout-grid {
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}
.grid-5 {
  grid-column-gap: 32px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
.image-header {
  margin-bottom: 16px;
  border-radius: 8px;
}
img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}
.box-1.no-border.vertical {
  margin-bottom: 1rem;
}
.box-1.vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    padding-right: 16px;
    padding-left: 16px;
  }
}
.box-1.no-border {
  padding: 0px;
  border-style: none;
}
.box-1 {
  display: flex;
  width: 100%;
  margin-right: 0px;
  padding: 20px 30px;
  align-items: flex-start;
  border: 1px solid #d6d3df;
  border-radius: 4px;
  h4 {
    margin-top: 10px;
    margin-bottom: 1rem;
    font-family: Bilo, sans-serif;
    color: #151a40;
    font-size: 1.5rem;
    line-height: 130%;
    font-weight: 700;
    letter-spacing: -0.5px;
    text-transform: none;
    @media screen and (max-width: 767px) {
      margin-top: 6px;
      font-size: 1.15rem;
    }
  }
}
.paragraph {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #5b5b5e;
  font-size: 1rem;
  line-height: 150%;
  text-align: left;
}
.full-width.border-bottom {
  margin-bottom: 6rem;
  border-bottom: 1px solid #d6d3df;
}
.full-width {
  padding-top: 4rem;
  padding-bottom: 4rem;
  h3 {
    margin-top: 20px;
    margin-bottom: 2rem;
    font-family: Bilo, sans-serif;
    color: #151a40;
    font-size: 2rem;
    line-height: 130%;
    font-weight: 700;
    text-transform: capitalize;
  }
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.w-tabs {
  position: relative;
}
.w-tabs:before,
.w-tabs:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}
.w-tabs:after {
  clear: both;
}
.tabs-menu {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  top: 0px;
  z-index: 1;
  margin-top: 0rem;
  border-bottom: 1px solid #d6d3df;
  background-color: #fff;
}
.w-tab-link.w--current {
  background-color: #c8c8c8;
}
.tab-link-for-organizers.w--current {
  border-bottom: 2px solid #f347ba;
  background-color: #fff;
  color: #23232e;
}

.w-tab-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  padding: 9px 30px;
  text-align: left;
  cursor: pointer;
  color: #222222;
  background-color: #dddddd;
}
.tab-link-for-organizers {
  margin-right: 1rem;
  padding: 2rem 8px;
  border: 1px none #000;
  background-color: #fff;
  color: #8f8e99;
  font-size: 1.1rem;
  font-weight: 500;
}
.tab-link-for-event-goers {
  padding: 2rem 8px;
  background-color: #fff;
  color: #8f8e99;
  font-size: 1.1rem;
  font-weight: 500;
}
.w-tab-content {
  position: relative;
  display: block;
  overflow: hidden;
}
.w-tab-content {
  position: relative;
  display: block;
  overflow: hidden;
}
.tab-pane-for-organizers {
  margin-top: -1px;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.flex-container {
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
}
.div-block-6 {
  width: 70%;
  padding-right: 6rem;
  @media screen and (max-width: 767px) {
    padding-right: 0rem;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
  }
}
.flex-container.top-aligned {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.checkmark-outl-ined {
  display: flex;
  width: 32px;
  height: 32px;
  margin-right: 0.8rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #f347ba;
  border-radius: 50%;
  text-align: center;
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.image-11 {
  width: 14px;
}
.box-1.no-border.vertical {
  margin-bottom: 1rem;
}
p {
  margin-bottom: 1rem;
  color: #5b5b5e;
  font-size: 1.1rem;
  line-height: 150%;
  font-weight: 300;
}
.image-7 {
  border-radius: 8px;
}
.div-block-12 {
  width: 60%;
  padding-right: 4rem;
  @media (max-width: 1300px) {
    margin-left: 20px;
  }
  @media screen and (max-width: 991px) {
    width: 100%;
    padding-right: 1rem;
  }
  h3 {
    margin-top: 20px;
    margin-bottom: 2rem;
    font-family: Bilo, sans-serif;
    color: #151a40;
    font-size: 2rem;
    line-height: 130%;
    font-weight: 700;
    text-transform: capitalize;
  }
  p {
    margin-bottom: 1rem;
    color: #5b5b5e;
    font-size: 1.1rem;
    line-height: 150%;
    font-weight: 300;
  }
}
.label-comingsoon {
  display: inline-block;
  padding: 0.1rem 1rem;
  border-radius: 20px;
  background-color: #d5a2fa;
  color: #fff;
  font-size: 0.7rem;
  line-height: 160%;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.list {
  margin-top: 2rem;
  color: #151a40;
}
.w-list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-item-2 {
  display: flex;
  margin-bottom: 1rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #151a40;
  font-size: 1.1rem;
}
.paragraph-xl {
  color: #7a7a7e;
  font-size: 1.5rem;
}
.bg-solid.pale {
  background-color: #fff6fc;
}
.bg-solid {
  margin-top: 2rem;
  margin-bottom: 0rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-radius: 8px;
  background-color: #fff;
}
.container-medium {
  max-width: 60rem;
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: 991px) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  h3 {
    margin-top: 20px;
    margin-bottom: 2rem;
    font-family: Bilo, sans-serif;
    color: #151a40;
    font-size: 2rem;
    line-height: 130%;
    font-weight: 700;
    text-transform: capitalize;
  }
  h2 {
    margin-top: 10px;
    margin-bottom: 2rem;
    font-family: Bilo, sans-serif;
    color: #151a40;
    font-size: 3.4rem;
    line-height: 110%;
    font-weight: 700;
    letter-spacing: -0.5px;
    text-transform: capitalize;
  }
}
.stats-grid {
  margin-bottom: 2rem;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  @media screen and (max-width: 991px) {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  @media screen and (max-width: 479px) {
    margin-bottom: 0rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}
.grid-5.desktop-3col {
  @media screen and (max-width: 479px) {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}
.stat-container {
  padding-left: 1rem;
  border-left: 1px solid #d6d3df;
}
.stats-number {
  font-family: Bilo, sans-serif;
  color: #310789;
  font-size: 4rem;
  line-height: 100%;
  font-weight: 800;
  @media screen and (max-width: 767px) {
    font-size: 2.6rem;
  }
}
.grid-3 {
  margin-top: 3rem;
  margin-bottom: 3rem;
  grid-column-gap: 0px;
  grid-row-gap: 21px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
.list-item.bigger {
  padding: 1rem 1rem 1rem 0.5rem;
  color: #23232e;
  font-size: 1rem;
}
.list-item {
  display: flex;
  padding: 0.5rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #f2f0f6;
  color: #151a40;
}
.feature-icn {
  width: 32px;
  height: 32px;
  margin-right: 0.5rem;
}
.cta-block.centered {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.centered {
  text-align: center;
}
.grid-4 {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  @media screen and (max-width: 479px) {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}
.trustpilot-review {
  border-radius: 8px;
  box-shadow: 0 4px 5px 1px rgb(209 155 193 / 25%);
}
.full-width.palebg {
  background-color: #fff6fc;
}
.paragraph.mt-0 {
  @media screen and (max-width: 767px) {
    font-size: 0.9rem;
  }
}
