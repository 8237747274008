.notification-settings-list {
  overflow: hidden;
  margin: 0 0 18px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  font-family: 'Poppins', 'HelveticaNeue', 'Helvetica', 'Arial', 'sans-serif';

  .container-fluid {
    .section {
      border-top: 1px solid #f0f0f0;
      line-height: 18px;

      &:first-child {
        border-top: none;
      }

      strong {
        font-size: 14px;
      }
    }
  }
}
