.checkout-input-label {
  font-size: 14px;
  line-height: 12px;
  color: #6e6d76;

  &.error {
    color: #ff3333;

    .checkout-input {
      border-color: #ff3333;
      color: #ff3333;
    }
  }

  .checkout-input {
    margin-top: 16px;
    padding: 22px 16px;
    font-size: 15px;
    line-height: 12px;
    letter-spacing: 0.01em;
    outline: none;
    background: #ffffff;
    border: 1px solid #d6d3df;
    box-sizing: border-box;
    border-radius: 4px;
    height: 56px;
    color: #23232e;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #abaab6;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #abaab6;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #abaab6;
    }
  }

  .checkout-input-container {
    .input-icon {
      position: absolute;
      right: 12px;
      bottom: 16px;
    }
  }
}
