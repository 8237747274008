.delete-profile-btn {
  background-size: 3px 1px;
  color: #888;
  font-size: 11px;
  line-height: 16px;
  padding: 13px 20px 12px 20px;

  button {
    width: 100%;
    color: #888;

    &:hover {
      color: #10345d;
    }

    i {
      display: flex;
    }
  }
}
