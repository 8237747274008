@import "../../../_definitions";

.pink-hr {
  height: 4px;
  background-color: $primary-pink;
}
.section-blocks {
  .text-s {
    max-width: 230px;
  }
}
