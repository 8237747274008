.trusted-by-box {
  margin-bottom: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  opacity: 1;
  text-align: center;
}
.trusted-logo {
  min-width: 0;
  margin: 0 16px;
  width: 120px;
  margin-right: 1rem;
}
