@import "../../../_definitions";

.dropdown-angle-pink {
  white-space: nowrap;
  position: relative;
  &:hover {
    @include current;
  }
  &::after {
    content: "";
    margin-left: 4px;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid $primary-pink;
  }
  &-mobile {
    &::after {
      content: "";
      margin-left: 4px;
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 9px solid $primary-pink;
    }
  }
}

.link-transition .show {
  .dropdown-angle-pink {
    @extend .current;
    &::after {
      border-bottom: 5px solid red;
      border-top: unset;
    }
  }
}
//////////////////////////////////////@at-root
///
.header-language {
  min-width: 40px;
}
.text-pink {
  color: $primary-pink;
  &:hover {
    color: $secondary-pink;
  }
}
.dropdown-menu {
  opacity: 0;
}
.dropdown-menu.show {
  opacity: 1;
}
.profile {
  .dropdown-menu {
    min-width: 280px;
  }
  .dropdown-item {
    padding: 0.5rem 1.5rem;
    white-space: nowrap;
  }
}

@media (min-width: 767px) {
  footer .navbar .navbar-collapse {
    padding: 0px 100px !important;
  }
}

.navbar {
  background-color: #10345d !important;
  padding: 0rem 1rem !important;

  form input {
    height: 30px;
    border-width: 2px;
    border-color: hsla(212.25, 0%, 100%, 1);
    border-radius: 2px;
    background-color: transparent;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    color: #0e335e;
    font-size: 15px;
    padding: 5px 12px;

    &:hover {
      background-color: hsla(0, 0%, 100%, 0.88);
    }

    &:focus {
      background-color: #fff;
      box-shadow: 1px 1px 50px 5px rgba(10, 28, 83, 0.61);
      color: #414543;
    }

    &::-webkit-input-placeholder {
      font-family: "Open Sans", sans-serif;
      color: hsla(215.9999999999998, 0%, 100%, 1);
      font-size: 13px;
      font-weight: 400;
    }

    &:-ms-input-placeholder {
      font-family: "Open Sans", sans-serif;
      color: hsla(215.9999999999998, 0%, 100%, 1);
      font-size: 13px;
      font-weight: 400;
    }

    &::-ms-input-placeholder {
      font-family: "Open Sans", sans-serif;
      color: hsla(215.9999999999998, 0%, 100%, 1);
      font-size: 13px;
      font-weight: 400;
    }

    &::placeholder {
      font-family: "Open Sans", sans-serif;
      color: hsla(215.9999999999998, 0%, 100%, 1);
      font-size: 13px;
      font-weight: 400;
    }
  }
  nav {
    height: 76px;

    .navbar-toggler-icon {
      color: #8d9aae !important;
    }

    .navbar-collapse {
      background-color: #10345d;
      z-index: 100;
      margin-top: -10px;
    }
  }

  span.count {
    background: #e62e83;
    color: white;

    font-weight: normal;
    font-size: 10px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    position: relative;
    z-index: 1000;
    padding: 0px 3px;
    top: -10px;
    right: 5px;

    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
  }

  a.button {
    color: #8d9aae !important;
    height: 76px;
    line-height: 76px;
    padding: 0px 18px !important;
    text-decoration: none;
    font-size: 15px;
    font-weight: 300;
    transition: 0.3s all;
    &:hover {
      background: #e62e83;
      color: #fff !important;
    }
    .notifications:hover {
      background-color: #1b426f;
      width: 56px;
      height: 76px;
    }
    .messages:hover {
      background-color: #1b426f;
      width: 56px;
      height: 76px;
    }
    .profilebtn:hover {
      background-color: #1b426f;
    }
  }

  .active {
    color: #fff !important;
    -webkit-box-shadow: inset 0px -3px 0px #e62e83;
    -moz-box-shadow: inset 0px -3px 0px #e62e83;
    box-shadow: inset 0px -3px 0px #e62e83;
  }

  a.notifications {
    width: 56px;
    height: 76px;
  }

  a.messages {
    width: 56px;
    height: 76px;
    margin: 0 6px;
  }

  .dropdown-menu {
    left: auto !important;
  }

  .notifications-dropdown .dropdown-menu div.head {
    font-size: 16px;
    font-weight: 300;
    height: 54px;
    line-height: 54px;
    text-align: left;
    padding: 0 22px;

    -webkit-transition: box-shadow 0.2s linear;
    -moz-transition: box-shadow 0.2s linear;
    -o-transition: box-shadow 0.2s linear;
    transition: box-shadow 0.2s linear;
  }

  .notifications-dropdown .dropdown-menu div.head a {
    color: #888;
    display: inline;
    float: right;
    font-size: 11px;
    padding: 0px;
    position: absolute;
    right: 15px;
    text-decoration: none;
    top: 11px;
  }

  .notifications-dropdown .dropdown-menu div.head a:hover {
    color: #10345d;
  }

  .notifications-dropdown .dropdown-menu ul.notifications {
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    min-width: 20rem;
  }

  a.notifications,
  a.notifications:hover,
  a.messages,
  a.messages:hover {
    background-position: center center;
    background-repeat: no-repeat;
    padding: 0px;
    position: relative;
    width: 56px;
  }

  a.notifications span.count,
  a.messages span.count {
    position: absolute;
    right: 7px;
    top: 12px;
  }

  a.dropdown-item span.count {
    top: 0px;
    left: 0px;
  }
}
/* NOTIFICATIONS ---------------------------------- */

ul.notifications {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  font-family: "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-size: 12px;
  line-height: 22px;
  color: #444;
  background-color: inherit;

  li {
    background: #f8f8f8;
    border-top: 1px solid #e2e2e2;
    line-height: 17px;
    min-height: 52px;
    padding: 10px 15px 8px 70px;
    position: relative;
  }

  li.empty {
    background: white;
    color: #888;
    height: 40px;
    line-height: 40px;
    min-height: 0px;
    padding: 0px;
    text-align: center;
  }

  li.new {
    background: white;
  }

  li.new:before {
    content: url(#{$domain}/img/web/new-notification-indicator.gif);
    left: 10px;
    position: absolute;
    top: 10px;
    z-index: 10;
  }

  li .image {
    height: 50px;
    left: 10px;
    padding: 0px;
    position: absolute;
    top: 10px;
    width: 50px;
  }

  li p {
    margin: 0px;
  }

  li p.result {
    color: #888;
  }

  li p a {
    color: #444;
  }

  li time {
    color: #888;
    float: right;
    font-size: 11px;
    margin-bottom: 5px;
    text-align: right;
    width: 90px;
  }

  li a {
    color: #10345d;
    display: inline;
    padding: 0px;
  }

  li a:hover {
    background: none;
    color: #10345d;
    text-decoration: underline;
  }

  li span {
    color: #888;
    padding: 0px 7px;
  }
}

nav.tabs a {
  color: white;
  display: block;
  font-family: Georgia, Times New Roman, serif;
  font-size: 20px;
  float: left;
  height: 44px;
  line-height: 44px;
  position: relative;
  text-align: center;
  width: auto;
}

nav.tabs a span {
  background: rgba(0, 0, 0, 0.1);
  color: white;
  display: inline-block;
  font-family: "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: normal;
  font-size: 11px;
  height: 16px;
  line-height: 16px;
  padding: 0px 5px;
  position: absolute;
  right: 15px;
  text-align: center;
  top: 14px;

  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

nav.tabs a.active {
  background: white;
  color: #444;
}

nav.tabs a.active span {
  background: #e8e8e8;
  color: #888;
}

nav.tabs a:hover {
  text-decoration: none;
}

nav.button-tabs {
  width: 100%;
}

nav.button-tabs a {
  color: #888;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 18px;
  position: relative;
  text-align: center;
  text-decoration: none;
}

nav.button-tabs a:last-child {
  margin-right: 0px;
}

nav.button-tabs a strong,
nav.button-tabs a span {
  display: block;
  padding: 3px 0px;
}

nav.button-tabs a strong {
  color: #444;
  font-size: 16px;
  line-height: 1;
}

nav.button-tabs a strong:first-child {
  margin: 12px 0px 3px 0px;
}

nav.button-tabs a span:first-child {
  margin: 12px 0px 1px 0px;
}

nav.button-tabs a.active {
  background: #10345d;
  color: white;
}

nav.button-tabs a.active strong,
nav.button-tabs a.active span {
  color: white !important;
}

nav.button-tabs a.active:before {
  border-color: #10345d transparent transparent transparent;
  border-style: solid;
  border-width: 10px 10px 0px 10px;
  bottom: -10px;
  content: "";
  display: block;
  height: 0px;
  left: 50%;
  margin-left: -10px;
  position: absolute;
  width: 0px;
  z-index: 1;

  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  transform: rotate(360deg);
}

nav.links a {
  color: #888;
  text-decoration: none;
}

nav.links a:hover {
  color: #444;
  text-decoration: none;
}

nav.links a.active {
  color: #10345d;
  font-weight: bold;
  text-decoration: none;
}

nav.links span {
  color: #d2d2d2;
  padding: 0px 4px;
}

.navbar a.title {
  background: #f0f0f0 !important;
  border-bottom: 1px solid #d2d2d2;
  border-top: 1px solid white;
  color: #444 !important;
  line-height: 12px;
  margin: -10px 0 10px 0;
  overflow: hidden;
}

.navbar a.title img {
  height: 25px;
}

.navbar a.title span {
  color: #888 !important;
  font-size: 10px;
}

.navbar a.title:hover span {
  color: #444 !important;
}

.navbar a.language {
  padding-top: 26px !important;
}
.widget-dropdown {
  border: 0;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  padding: 20px;
}
.language-link {
  font-size: 0.8125rem;
  &:hover {
    @extend .current;
  }
}
a.current {
  background: $grey;
  border-radius: 32px;
  color: $dark;
}
.text-grey {
  color: $text-grey;
}
a.text-grey {
  &:hover {
    color: $dark;
  }
}

/* LANGUAGE FLAGS --------------------------------- */

.language-flag {
  background: url(#{$domain}/img/web-v2/common/flags.png) no-repeat;
  background-size: 24px 140px;
  height: 20px;
  width: 24px;

  &.shadowless {
    background-image: url(#{$domain}/img/web-v2/common/flags.png);
  }

  &.en_UK {
    background-position: 0px 0px;
  }
  &.bg_BG {
    background-position: 0px -20px;
  }
  &.es_MX {
    background-position: 0px -40px;
  }
  &.es_ES {
    background-position: 0px -60px;
  }
  &.ru_RU {
    background-position: 0px -80px;
  }
  &.gr_GR {
    background-position: 0px -100px;
  }
  &.ro_RO {
    background-position: 0px -120px;
  }
}
.language-flag {
  background-image: url(#{$domain}/img/web-v2/common/flags.png);

  &.shadowless {
    background-image: url(#{$domain}/img/web-v2/common/flags.png);
  }
}
.category-dropdown {
  right: -300px;
  padding: 22px 29px 36px;
  .dropdown-item {
    border-radius: 55px;
    padding: 0.5rem 1rem;
  }
  .label-m {
    color: #8f8e99;
    white-space: nowrap;
    line-height: 32px;
    border-bottom: 1px solid $grey;
  }
}
.organizers-dropdown {
  @extend .category-dropdown;
  min-width: 396px;
  padding: 0;
  right: 0;
  padding-bottom: 22px;
  .dropdown-item {
    border-radius: 55px;
    padding: 0.5rem 1rem;
    width: auto;
  }
  .category-label {
    font-family: "Bilo", "Poppins", Helvetica, Arial, sans-serif;
    white-space: normal;
    color: #23232e;
  }
}
.profile {
  .dropdown-menu {
    min-width: 280px;
  }
  .dropdown-item {
    padding: 0.5rem 1.5rem;
    white-space: nowrap;
  }
}
.dropdown-item {
  white-space: normal;
}
.nav-wrap {
  max-width: 490px;
}
.widget-dropdown {
  border: 0;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  padding: 20px;
}
.header-search-wrap {
  @include widget-dropdown;
  color: #d6d3df;
}
.more-categories-dropdown,
.organizers-container {
  max-height: 80vh;
  overflow-y: scroll;
}
.circle-gray {
  width: 32px;
  height: 32px;
  background-color: #f6f5fa;
  border-radius: 50%;
  &-sm {
    width: 22px;
    height: 22px;
    font-size: 0.75rem;
  }
}
.suggestions {
  top: calc(100% + 20px);
  left: 0;
  right: 0;
  width: calc(100% - 10px);
  z-index: 9;
  padding: 25px;
  max-height: 80vh;
  overflow-y: scroll;
  .category-label {
    font-size: 0.8125rem;
    font-weight: 600;
  }
}
.suggestion-card-place {
  color: #70757d;
  font-weight: 400;
}
.suggestion-title {
  @extend .category-label;
  color: $dark;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.suggestion-active {
  .suggestion-card {
    background-color: #f2f4f7;
  }
}
.suggestion-card {
  &:hover {
    background-color: #f2f4f7;
  }
}
.suggestion-card.card {
  border-radius: 0;
}
.suggestion-card-bg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  width: 100px;
  height: 75px;
}
.suggestion-link {
  min-height: 75px;
}
.category-label {
  white-space: nowrap;
  font-weight: 700;
  font-size: 1.125rem;
  color: #8f8e99;
}
.logged-menu {
  border-radius: 32px;
  min-width: 100px;
}
.custom-drop.dropleft .dropdown-menu {
  right: 0;
  top: 100%;
}
.menu-transition-to-bottom {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  top: 75%;
  display: block;
  overflow: hidden;
  opacity: 0;
  z-index: -1;
}

.notifications-dropdown {
  max-width: 170px;
}
.letter-spacing-5 {
  letter-spacing: 0.05rem;
}

/* JEDITABLE -------------------------------------- */

.jeditable {
  margin: 0px;
  padding: 0px;
  width: 100% !important;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.jeditable input,
.jeditable textarea {
  min-height: 30px;
  padding: 0px 5px;
  width: inherit;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.jeditable.name {
  margin: 4px 0px;
}

.jeditable.name input {
  font-family: Georgia, Times New Roman, serif;
  font-size: 20px;
  height: 40px !important;
}

.jeditable.short-description {
  margin: 3px 0px 4px 0px;
}

.jeditable.short-description input {
  color: #888;
  font-family: Georgia, Times New Roman, serif;
  font-size: 14px;
  height: 30px !important;
}

.jeditable.description {
  margin: 25px 0px 7px 0px;
}

.jeditable.description textarea {
  line-height: 22px;
  min-height: 100px;
  padding: 5px;
}

.jeditable.profile-name input {
  border: none;
  color: #444;
  font-size: 16px;
  font-weight: bold;
  height: 24px !important;
  left: 0px;
  min-height: 24px;
  position: absolute;
  top: 8px;
  text-align: center;

  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.jeditable.linked-list-name {
  margin: 0px;
}

.jeditable.linked-list-name input {
  font-size: 14px;
  height: 40px !important;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 620px;
}

.profile {
  .dropdown-menu {
    min-width: 280px;
  }
  .dropdown-item {
    padding: 0.5rem 1.5rem;
    white-space: nowrap;
  }
}

div.profile {
  position: relative;
}
.username-letter {
  width: 32px;
  height: 32px;
  background: #f5e1fe;
  border-radius: 50%;
  font-size: 0.8125rem;
  color: $secondary-violet;
  overflow: hidden;
  border: 1px solid #f5e1fe;
}
.label-l {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;
}
.has-sub {
  max-height: 50px;
  min-height: 48px;
  overflow: hidden;
}
.submenuOpened {
  &.has-sub {
    max-height: 100vh;
  }
  .menu-left {
    right: 0;
  }
  > .mobile-category {
    &::after {
      content: "";
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 9px solid $light-grey;
      border-top: 0;
    }
  }
}
@media all and (min-width: 768px) {
  .desktop-nav {
    display: flex;
  }
  .mobile-navigation {
    display: none;
  }
}
.mobile-nav-header {
  max-height: 64px;
  border-bottom: 1px solid $grey;
}
footer nav {
  background: #e6e6e6;
  border-top: 1px solid #d7d7d7;
  z-index: 1030;
  line-height: 50px;

  button.collapsed {
    margin-right: 0px;
  }

  .navbar-brand {
    font-size: inherit;
    color: #8a94a5;
  }

  a {
    color: #63676d;
  }
}
.search-icon-action {
  width: 30px;
}
.custom-drop.dropleft .dropdown-menu {
  right: 0;
  top: 100%;
}
.menu-transition-to-bottom {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  top: 75%;
  display: block;
  overflow: hidden;
  opacity: 0;
  z-index: -1;
}
.when-dropdown {
  @extend .category-dropdown;
  min-width: 250px;
  .nav-link-normal {
    color: #9da0a7;
    &.current {
      color: $dark;
    }
  }
}
.text-mode-dark {
  color: $light-mode-text !important;
}
.mobile-nav-title {
  font-family: "Bilo", "Poppins", Helvetica, Arial, sans-serif;
  color: #23232e;
  line-height: 24px;
  font-weight: 700;
}
.mobile-footer-content {
  border-top: 1px solid $grey;
}
.nav-button-ghost {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 32px;
  padding-top: 11px;
  padding-bottom: 11px;
  white-space: nowrap;
  &:hover {
    border-color: $light-mode-text;
  }
}

.button-ghost {
  background: #ffffff;
  border: 1px solid #d6d3df;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(195, 116, 247, 0.2);
  border-radius: 32px;
  z-index: 1;
  font-weight: 600;
  font-size: 15px;
  line-height: 32px;
  text-align: center;
  padding: 10px 30px;
  white-space: nowrap;
  &:hover {
    border-color: #6e6d76;
  }
}

.nav-button-primary {
  background: $primary-pink;
  border-radius: 55px;
  padding-top: 9px;
  padding-bottom: 9px;
  white-space: nowrap;
  &:hover {
    background-color: $secondary-pink;
    color: #fff;
  }
}
.suggestion-card-place {
  color: #70757d;
  font-weight: 400;
}
.suggestion-card {
  &:hover {
    background-color: #f2f4f7;
  }
}
.suggestion-card.card {
  border-radius: 0;
}
.suggestion-card-bg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  width: 100px;
  height: 75px;
}
.suggestion-link {
  min-height: 75px;
}
.card-date {
  background-color: #23232e;
  z-index: 2;
  transition: 0.3s ease-in;
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.25;
}
.link-normal {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 16px;
  color: $dark;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    background: $dark;
  }
  &:hover {
    color: $link-pink;
    &::after {
      width: 0;
    }
  }
}
.dropdown-link {
  @extend .link-normal;
  font-size: 0.8125rem;
  line-height: 1;
  &::after {
    background: $dark;
  }
  .icon {
    top: 0;
    left: calc(100% + 5px);
  }
}
.search-input {
  min-width: 280px;
  font-size: 0.8125rem;
  width: auto;
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  background-color: rgba(241, 238, 245, 0.5);
  padding-top: 12px;
  padding-bottom: 12px;
  &::placeholder {
    font-size: 0.875rem !important;
    color: #8f8e99 !important;
  }
}
#clearSearch {
  cursor: pointer;
}
.fad {
  position: relative;
  font-family: "Font Awesome 5 Duotone";
  font-weight: 900;
}
