.ticket-card {
  width: 210px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  position: absolute;
  float: right;

  font-family: "Poppins", "HelveticaNeue", "Helvetica", "Arial", "sans-serif";
  font-size: 16px;
  line-height: 22px;
  color: #444;

  strong.label {
    color: #b2b2b2;
    display: block;
    font-size: 10px;
    line-height: 12px;
    padding-bottom: 1px;
    text-transform: uppercase;
  }

  span.value {
    display: block;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .event-details {
    border-bottom: 1px solid #f0f0f0;
    display: block;
    height: 82px;
    padding: 16px 20px 0px 20px;
    text-decoration: none;
    width: 190px;
    color: #10345d;
    text-align: left;

    & > strong {
      color: #222;
      display: block;
      font-size: 16px;
      line-height: 18px;
      padding-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    span {
      color: #888;
      display: block;
      line-height: 16px;
      width: 190px;
    }

    strong + span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .ticket-details {
    border-bottom: 1px solid #f0f0f0;
    height: 83px;
    padding: 7px 0px 0px 20px;

    .label {
      padding-top: 5px;
    }

    .color {
      display: block;
      float: left;
      height: 10px;
      margin: 4px 5px 0px 0px;
      width: 10px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
    }
  }

  .ticket-codes {
    height: 95px;

    img {
      float: left;
      height: 65px;
      margin: 15px 20px 15px 20px;
      width: 65px;
    }

    .number {
      padding-top: 12px;
    }

    .label {
      padding-bottom: 2px;
    }

    .value {
      font-size: 18px;
    }

    .code {
      padding-top: 6px;
    }
  }

  .signature {
    background: #10345d;
    color: #e62e83;
    font-size: 9px;
    font-weight: bold;
    height: 25px;
    text-align: center;
    text-transform: uppercase;
    -webkit-border-radius: 0px 0px 3px 3px;
    -moz-border-radius: 0px 0px 3px 3px;
    border-radius: 0px 0px 3px 3px;

    img {
      height: 25px;
      margin-left: 2px;
      vertical-align: -9px;
      display: inline-block;
    }
  }
}
