@import "../../../_definitions";

.pagination-button {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 22px;
  letter-spacing: 0.01rem;
  &--prev,
  &--next {
    opacity: 0.7;
    font-size: 1.5rem;
  }
  &--current {
    font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
    font-weight: 600;
    font-size: 0.8125rem;
    line-height: 24px;
    background-color: #450ab8;
    color: #fff;
  }
  &--disabled {
    opacity: 0.3;
  }
  &:hover {
    background-color: #f6f5fa;
    opacity: 1;
  }
}
