@import "../../../_definitions";

.result-list-item {
  border-bottom: 1px solid #f1eef5;
}
.search-result-image {
  width: 180px;
  height: 120px;
  background-size: cover;
  background-position: center;
}
.e-card h6.card-subtitle {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
.date,
.location {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  font-style: normal;
  color: #49494f;
  letter-spacing: 0.01em;
  line-height: 22px;
}
.location {
  font-weight: normal !important;
}
.pink-dot-before {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    right: calc(100% + 5px);
    top: 50%;
    transform: translateY(-50%);
    bottom: 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #f347ba;
  }
}
.search-result-tag {
  font-family: "Bilo", "Poppins", Helvetica, Arial, sans-serif;
  font-size: 0.6875rem;
  color: $dark-grey;
  letter-spacing: 0.06rem;
}
