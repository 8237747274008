.user-details-wrapper {
  .information {
    color: #888;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    text-align: center;

    li:first-child {
      padding-top: 16px;
    }

    li {
      padding: 1px 0px;

      span {
        color: #10345d;
      }
    }

    li:last-child {
      padding-bottom: 16px;
    }
  }

  .buttons-container {
    .edit {
      background-position: 0px -264px;
    }
    .friend {
      background-position: 0px -132px;
    }
    .message {
      background-position: 0px -220px;
    }
    .cancel,
    .decline {
      background-position: 0px -44px;
    }
    .accept {
      background-position: 0px 0px;
    }
    .unfriend {
      background-position: 0px -176px;
    }

    .primary {
      background-color: #e62e83;
    }

    .settings {
      background-position: 0px -308px;
    }

    button {
      background-image: url(https://local.evedo.co/img/web-v2/common/round-buttons.png);
      background: #d2d2d2 url(https://local.evedo.co/img/web-v2/common/round-buttons.png?2) no-repeat;
      background-size: 44px 704px;
      display: inline-block;
      font-size: 12px;
      height: 44px;
      margin-right: 5px;
      width: 44px;
      -webkit-border-radius: 22px;
      -moz-border-radius: 22px;
      border-radius: 22px;
    }

    button:last-child {
      margin-right: 0px;
    }
  }
}
