.event-tickets-mobile {
  margin-bottom: 50px;
  border-top: 1px solid #e5e8ec;
  background: #fff;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 999;
  -webkit-box-shadow: 0px -2px 18px 0px rgba(26, 27, 27, 0.15);
  -moz-box-shadow: 0px -2px 18px 0px rgba(26, 27, 27, 0.15);
  box-shadow: 0px -2px 18px 0px rgba(26, 27, 27, 0.15);

  .price-line {
    font-size: 1rem;
    line-height: 150%;
    color: #608cbe;
    margin-bottom: 1rem;

    img {
      display: inline-block;
    }

    .date-highlight {
      font-size: 1rem;
      text-transform: uppercase;
      color: #10345d;
      font-weight: 600;
      display: inline-block;
      border-right: 1px solid rgba(152, 183, 220, 0.3);
      padding: 0px;
    }

    .btn-primary:hover {
      background-color: #d60d6a;
      border-color: #d60d6a;
    }
  }
}
