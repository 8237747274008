.checkout-event-info {
  border-bottom: 1px solid #d6d3df;

  .title {
    margin: 8px 4px 0 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .info {
    margin: 4px 4px 18px 4px;

    .ticket-icon {
      display: inline;
      margin-right: 4px;
      height: 16px;
      width: 16px;
    }
  }
}
