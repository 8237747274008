@import "../../../../_definitions";

.latest-evedo {
  .slick-list {
    padding: 0 50px 0 0 !important;
  }
  .slick-slide {
    padding: 16px 0 16px 16px;
  }
}
.latest-button {
  padding: 12px 24px;
  border-radius: 67px;
  &:hover,
  &.selected {
    color: $dark;
    background-color: #fff;
  }
}
@media all and (min-width: 768px) {
  .latest-slider {
    top: 0;
    left: 0;
    max-width: calc((100vw - 1220px) / 2 + 915px);
    &-wrap {
      height: calc(((100vw - 1220px) / 2 + 915px) / 5);
    }
  }
}
.slide-item-title {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 20px;
  letter-spacing: 0.01rem;
  font-weight: 500;
  color: $dark-grey;
  letter-spacing: -0.01em;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
}
