.friend-card {
  background: #f8f8f8;

  &.pending {
    .col-4 {
      .image {
        opacity: 0.5;
      }
    }

    .col-8 {
      .round-buttons {
        .unfriend {
          background-position: 0px -44px;
        }
      }
    }
  }

  & > .row {
    background: white;
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
  }

  .col-4 {
    .image {
      display: block;
      height: 105px;
      width: 105px;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  .col-8 {
    .user {
      color: #444;
      display: block;
      font-family: "Georgia", "Times New Roman", "serif";
      font-size: 16px;
      max-width: 220px;
      text-overflow: ellipsis;
      white-space: nowrap;
      top: 34px;
    }

    .location {
      color: #888;
      display: block;
      left: 125px;
      max-width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
      top: 55px;
      white-space: nowrap;
      font-family: "Poppins", "HelveticaNeue", "Helvetica", "Arial", "sans-serif";
      font-size: 0.875rem;
      font-style: normal;
      letter-spacing: 0.01em;
      line-height: 22px;
      font-weight: normal;
    }

    .round-buttons {
      opacity: 1;
      right: 20px;
      -webkit-transition: opacity 0.1s linear, right 0.2s ease-out;
      -moz-transition: opacity 0.1s linear, right 0.2s ease-out;
      -o-transition: opacity 0.1s linear, right 0.2s ease-out;
      transition: opacity 0.1s linear, right 0.2s ease-out;

      .primary {
        background-color: #e62e83;
      }

      .accept {
        background-position: 0px 0px;
        background-image: url(https://local.evedo.co/img/web-v2/common/round-buttons.png);
      }

      .unfriend {
        background-position: 0px -176px;
      }

      .message {
        background-position: 0px -220px;
      }

      .add-friend {
        background-position: 0px -132px;
      }

      button {
        background-image: url(https://local.evedo.co/img/web-v2/common/round-buttons.png);
        background: #d2d2d2 url(https://local.evedo.co/img/web-v2/common/round-buttons.png?2) no-repeat;
        background-size: 44px 704px;
        display: inline-block;
        font-size: 12px;
        height: 44px;
        margin-right: 5px;
        width: 44px;
        -webkit-border-radius: 22px;
        -moz-border-radius: 22px;
        border-radius: 22px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}
