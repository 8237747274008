.conversation-body-wrapper {
  bottom: 91px;
  left: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  max-height: 240px;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
}
