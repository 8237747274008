.calendar-filters-widget {
  h3 {
    font-size: 20px;
    line-height: 24px;
    font-family: "Bilo", "Poppins", "Helvetica", "Arial", "sans-serif";
    color: #151a40;
    font-weight: 500;
    margin: 0;
    color: inherit;
    letter-spacing: -0.5px;
    text-rendering: optimizelegibility;
  }

  .calendar-filters-container {
    margin: 0 0 18px;

    .btn {
      width: 100%;
      background-image: url(https://local.evedo.co/img/web/icon-tick-white.png);
      background-position: 6px 20px;
      background-repeat: no-repeat;
      display: block;
      text-decoration: none;

      &:hover {
        color: white;
        -webkit-box-shadow: inset 0px 100px 0px rgba(0, 0, 0, 0.08);
        -moz-box-shadow: inset 0px 100px 0px rgba(0, 0, 0, 0.08);
        box-shadow: inset 0px 100px 0px rgba(0, 0, 0, 0.08);
      }

      &.marker-green {
        background-color: #12982b;
        color: white;
      }

      &.marker-yellow {
        background-color: #f6a131;
        color: white;
      }

      &.marker-pink {
        background-color: #e62e83;
        color: white;
      }

      &.unchecked {
        background-color: #f0f0f0;
        color: #888;
        background-image: initial;
      }
    }
  }
}
