@import "../../definitions";
.description {
  overflow: hidden;
}
.text {
  overflow: hidden;
  padding: 0px 20px 18px 20px;
}
#full-description {
  border: 1px solid #e8e8e8;
  // display: none;
  height: 23px;
  line-height: 23px;
  margin: 0px 20px 20px 0px;
  position: relative;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  :after {
    background: url(#{$domain}/img/web/white-gradient.png) repeat-x;
    bottom: 24px;
    content: "";
    display: block;
    height: 20px;
    left: -21px;
    position: absolute;
    width: 730px;
  }
  span {
    // background: url(#{$domain}/img/web/expand-arrows.gif) no-repeat top right;
    padding: 0px 12px 0px 6px;
  }
}
.show {
  display: none;
  background-position: bottom right;
}
.hide {
  display: inline-block;
}
ul.contacts li {
  height: 22px;
  line-height: 22px;
  overflow: hidden;
  a {
    color: #0000ee;
  }
}
