.ticket-options-wrapper {
  height: 59px;
  line-height: 18px;
  padding: 21px 0px 0px 10px;
  position: relative;
  text-align: left;
  width: 220px;
  margin-top: 300px;

  strong {
    font-size: 16px;
  }

  span {
    color: #888;
  }

  .buttons {
    position: absolute;
    right: 10px;
    top: 28px;

    & > a {
      background: #d2d2d2;
      color: #ffffff;
      display: block;
      float: left;
      font-size: 12px;
      height: 24px;
      line-height: 24px;
      padding: 0px 8px;
      text-decoration: none;
      -webkit-border-radius: 2px 0px 0px 2px;
      -moz-border-radius: 2px 0px 0px 2px;
      border-radius: 2px 0px 0px 2px;

      &:hover {
        transition: 0.3s all;
        background: #e62e83;
        color: #ffffff;
      }
    }

    .more {
      background: #d2d2d2 url(https://local.evedo.co/img/web-v2/icons/arrow-bottom-white.gif) no-repeat 6px 7px;
      background-size: 11px 11px;
      float: left;
      height: 24px;
      margin-left: 1px;
      position: relative;
      width: 23px;
      -webkit-border-radius: 0px 2px 2px 0px;
      -moz-border-radius: 0px 2px 2px 0px;
      border-radius: 0px 2px 2px 0px;

      .dropdown {
        background: #111;
        display: none;
        position: absolute;
        padding: 4px 0px;
        right: 0px;
        top: 25px;
        width: 210px;
        z-index: 20;
        -webkit-border-radius: 2px 0px 2px 2px;
        -moz-border-radius: 2px 0px 2px 2px;
        border-radius: 2px 0px 2px 2px;
        -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);

        a {
          color: #ffffff;
          display: block;
          height: 26px;
          line-height: 26px;
          text-align: center;
          text-decoration: none;
          text-align: center;
          width: 100%;

          &:hover {
            transition: 0.3s all;
            background: #e62e83;
          }
        }

        div {
          background: rgba(255, 255, 255, 0.08);
          height: 1px;
          width: 100%;
        }
      }

      &:hover {
        background-color: #111;
        height: 25px;
        -webkit-border-radius: 0px 2px 0px 0px;
        -moz-border-radius: 0px 2px 0px 0px;
        border-radius: 0px 2px 0px 0px;

        .dropdown {
          display: block;
        }
      }
    }
  }
}
