.checkout-btn {
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: #ff3cbf;
  border: none;
  border-radius: 6px;
  width: 100%;

  &:hover {
    transition: 0.3s ease;
    background: #fd17b1;
  }
}
