.event-ticket-date {
  background: #10345d;
  color: white;
  display: block;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  padding-left: 5px;
  text-align: center;
}
