.event-tag {
  color: #2b7fe5;
  letter-spacing: 0.2px;
  font-weight: 600;
  border-color: #e4efff;
  background-color: #e4efff;
  border: 1px solid #f2f2f5;
  border-radius: 3px;
  font-size: 0.835rem;
  padding: 4px 12px;
  margin-top: 4px;
  margin-right: 4px;
  transition: 0.3s all;
}
