.checkout-ticket-category-card {
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #eceaf0;
  border-radius: 4px;
  align-items: center;

  @media (min-width: 768px) and (max-width: 1050px), (max-width: 420px) {
    flex-direction: column;
    align-items: initial !important;

    .right-container {
      justify-content: flex-end !important;
    }
  }

  .left-container {
    border-left: 4px solid;
    margin-right: 10px;

    .description {
      margin-top: 4px;
      color: #6e6d76;
    }
  }

  .right-container {
    .price-container {
      .price {
        font-weight: 600;
      }

      .amount-left {
        color: #49494f;
        white-space: pre;
      }
    }

    .amount-select {
      outline: none;
      width: 67px;
      height: 41px;
      background: #ffffff;
      border: 1px solid #f1eef5;
      box-sizing: border-box;
      border-radius: 6px;
      font-size: 13px;
      line-height: 19px;
      color: #23232e;
      padding-left: 12px;
    }
  }

  .title,
  .price {
    font-size: 13px;
    line-height: 19px;
    color: #23232e;
  }

  .description,
  .amount-left {
    font-size: 11px;
    line-height: 16px;
  }
}
