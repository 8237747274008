.checkout-navigation {
  padding: 24px 0 32px 0;

  .checkout-nav-link {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.533333px;
    color: #23232e;
    opacity: 0.3;
    border: none;
    background: transparent;

    &:not(:last-child) {
      margin-right: 14px;
    }

    &.completed {
      opacity: 1;
    }
  }

  svg {
    margin-right: 14px;
  }
}
