.online-slider {
  .card {
    max-width: 320px;
  }
  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    height: inherit !important;
    padding-right: 16px;
  }
  .card-bg {
    height: 130px;
    border-radius: 8px;
  }
  .custom-prev-arrow {
    left: 0;
    top: 50px;
    z-index: 2;
  }
  .custom-next-arrow {
    right: 0;
    top: 50px;
    z-index: 2;
  }
  .slick-slider {
    margin-right: -16px;
    padding-left: 22px;
    padding-right: 20px;
  }
  .card-date {
    font-size: 0.67875rem;
    padding: 11px 9px;
  }
}
