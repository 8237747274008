@import "../../_definitions";

.flex-container.testimonial.middle {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.testimonial-container {
  max-width: 60rem;
  margin-top: 0rem;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
.paragraph-testimonial {
  max-width: 900px;
  text-align: center;
  font-family: Poppins, sans-serif;
  opacity: 0.8;
  color: #151a40;
  font-size: 1.4rem;
  font-weight: 400;
}
.div-block-9 {
  width: 20%;
  margin-bottom: 4rem;
  text-align: center;
  @media screen and (max-width: 991px) {
    width: 80%;
  }
}
.testimonial-name {
  color: #151a40;
  font-size: 1rem;
  line-height: 150%;
  font-weight: 500;
  margin-bottom: 8px;
}
.testimonial-title p {
  margin: 0;
  color: #151a40;
  font-size: 0.9rem;
  line-height: 150%;
}
