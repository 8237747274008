.profile-image-wrapper {
  max-width: 350px;
  margin: auto;
  .picture-form {
    position: relative;
    width: 100% !important;
    img {
      aspect-ratio: 1;
      max-height: 350px !important;
    }
    .browse {
      opacity: 0;
      font-size: 11px;
      text-align: center;
      background: rgba(0, 0, 0, 0.8);
      color: #d2d2d2;
      left: 0px;
      line-height: 16px;
      padding: 0px;
      position: absolute;
      top: 0px;
      width: 100%;
      height: 100%;
      -webkit-transition: opacity 0.02s linear;
      -moz-transition: opacity 0.02s linear;
      -o-transition: opacity 0.02s linear;
      transition: opacity 0.02s linear;

      strong {
        display: block;
        margin-top: 98px;
        color: white;
        font-size: 16px;
      }

      input {
        cursor: pointer;
        top: 0px;
        height: 100%;
        font-size: 180px;
        left: 0px;
        opacity: 0;
        position: absolute;
        width: 100%;
      }

      .remove {
        background: rgba(0, 0, 0, 0.2) url(https://local.evedo.co/img/web/icon-remove-white.gif) no-repeat 8px 8px;
        display: block;
        height: 24px;
        position: absolute;
        right: 0px;
        top: 0px;
        width: 24px;
        z-index: 1;
      }
    }

    &:hover {
      .browse {
        opacity: 1;
      }
    }
  }

  .name {
    background: #10345d;
    color: white;
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    padding: 0px 8px;
    position: relative;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;

    input {
      color: white;
      width: auto;
      height: auto;
      top: 0px;
      position: relative;
      background-color: #10345d;
      border: none;
      outline: none;
      font-size: 16px;
      font-weight: bold;
      left: 0px;
      min-height: 24px;
      text-align: center;
      padding: 0px 5px;
    }
  }
}
