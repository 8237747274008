.checkout-event-pricing {
  padding-bottom: 13px;

  .chosen-tickets-list {
    border-bottom: 1px solid #d6d3df;
  }

  .total-prices-container {
    .fees,
    .total,
    .discount {
      text-align: right;
      margin-bottom: 8px;
    }

    p.fees {
      font-weight: 300;
      letter-spacing: 0.5px;
      color: #23232e;
    }

    p.total {
      font-size: 14px;
      line-height: 21px;
      color: #23232e;
    }

    p.discount {
      color: #8f8e99;
      font-weight: 400;

      .discount-link {
        color: #8f8e99;
        background: transparent !important;
        text-decoration: underline;
      }
    }
  }
}
