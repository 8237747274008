@import "../../../definitions";

.embed-wrapper {
  max-width: 750px;
  margin: auto;
}
.modal-header {
  background: #000;
  color: #ffffff;
  height: 130px;
  padding: 0px;
  .h2 {
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 24px;
    line-height: 30px;
    padding: 24px 0px 3px 0px;
  }
  p {
    color: #888;
    font-size: 16px;
    line-height: 25px;
    padding: 0px 0px 14px 0px;
  }
}
.embed-ticket-body {
  margin: 0px;
  padding: 0;
  overflow: visible;
  position: relative;
  -webkit-box-shadow: inset 0px 1px 2px 0px #e2e2e2;
  -moz-box-shadow: inset 0px 1px 2px 0px #e2e2e2;
  box-shadow: inset 0px 1px 2px 0px #e2e2e2;
  flex: 1 1 auto;
  padding: 1rem;
  h2 {
    font-family: "Maven Pro", Arial, sans-serif;
    font-size: 18px;
    font-weight: bold;
    height: 53px;
    line-height: 52px;
  }
  select {
    border-bottom: 0px;
    margin-top: 20px;
    margin: 0px;
    display: inline-block;
  }
  input {
    margin-bottom: 20px;
    padding: 6px 5px;
  }
  textarea {
    cursor: default;
    font-family: "Lucida Console", Monaco, monospace;
    font-size: 10px;
    height: 29px;
    line-height: 14px;
    overflow: hidden;
    padding-top: 6px;
    resize: none;
    white-space: nowrap;
    transition: none;
  }
  @media screen and (max-width: 800px) {
    .form-wrapper {
      flex-direction: column;
      align-items: center;
    }
  }
}

.text-center {
  text-align: center !important;
}
.modal#embed-tickets-modal div.modal-body div.layout.button-normal {
  background-position: 0px -148px;
}

.layout {
  background: url(#{$domain}/img/web-v2/event/embed-tickets/layouts.png);
  background-size: 210px 644px;
  height: 148px;
  width: 210px;
  display: inline-block;
}
.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.form-control {
  transition: none;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

select {
  background: white url(https://local.evedo.co/img/web-v2/common/select-arrow.gif) no-repeat center right;
  background-size: 19px 11px;
  font-size: 12px;
  line-height: normal;
  margin-top: 10px;
  padding: 0px 0px 0px 5px;
  text-indent: 2px;
  width: 200px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0px;
}
div.layout {
  background: url(https://local.evedo.co/img/web-v2/event/embed-tickets/layouts.png);
  background-size: 210px 644px;
  height: 148px;
  width: 210px;
  display: inline-block;
}
.size {
  color: #888;
  font-size: 14px;
  height: 51px;
  line-height: 50px;
  strong {
    color: #444;
  }
}
.popover-header {
  background: green !important;
  max-height: 50px !important;

  h3,
  p {
    color: #eff8f1;
    margin: 0;
    padding: 0;
    font-size: 13px;
  }
}
