.manage-discount {
  min-height: 90vh;
  background: white;
  .wrapper {
    height: 400px;
    .divider {
      background: #e8e8e8;
      float: left;
      height: 190px;
      margin: 105px 15px 0px 14px;
      position: relative;
      width: 1px;
      span {
        background: #ffffff;
        border: 1px solid #e8e8e8;
        color: #888;
        font-size: 26px;
        left: -50%;
        line-height: 36px;
        margin: -20px 0px 0px -22px;
        top: 50%;
        padding: 10px;
      }
    }
    a {
      background: #12982b;
      color: #ffffff;
      display: block;
      font-size: 13px;
      font-weight: bold;
      height: 30px;
      line-height: 28px;
      margin: 0px auto;
      text-align: center;
      width: 140px;
    }
  }
}
