.additional-details-modal-wrapper {
  display: block !important;
  z-index: 10;

  .additional-details-modal {
    .modal-content {
      .modal-header {
        h3 {
          font-family: 'Bilo', 'Poppins', 'Helvetica', 'Arial', 'sans-serif';
          color: #151a40;
          font-weight: 500;
          margin: 0;
          color: inherit;
          letter-spacing: -0.5px;
          text-rendering: optimizelegibility;
          font-size: 20px;
          line-height: 24px;
        }

        .close {
          padding: 1rem 1rem;
          margin: -1rem -1rem -1rem auto;
          float: right;
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1;
          color: #000;
          text-shadow: 0 1px 0 #ffffff;
          opacity: 0.5;
        }
      }

      .modal-body {
        width: 100% !important;
        padding: 10px 0px 10px 30px !important;
        .edit-profile-modal {
          width: 520px;
          margin: 0 0 18px;

          fieldset {
            width: 250px;

            label {
              color: #888;
              line-height: 10px;
              padding: 0px;
              margin-top: 9px;
              font-size: 12px;
            }

            select {
              margin-top: 9px;
              width: 235px;
              background: white url(https://local.evedo.co/img/web-v2/common/select-arrow.gif) no-repeat center right;
              background-size: 19px 11px;
              padding: 0px 0px 0px 5px;
              text-indent: 2px;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              border: 1px solid #d2d2d2;
              outline: none;
            }

            select:not(:first-of-type) {
              margin-left: 2.5px;
            }

            input {
              width: 235px;
              height: 28px;
              font-family: inherit;
              font-size: 100%;
              line-height: inherit;
              color: inherit;
              margin: 0;
              padding: 1px 2px;
              background-color: #ffffff;
              border: 1px solid #d2d2d2;
              outline: none;
            }
          }
        }
      }

      .modal-footer {
        .btn-primary {
          background-color: #e62e83;
          border: 0;
          border-radius: 2px;

          &:hover {
            color: #ffffff;
            background-color: #0e2c4f;
            border-color: #0d2a4a;
          }
        }
      }
    }
  }
}
