.calendar-wrapper {
  position: relative;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  .cal {
    -webkit-transition: width 0.2s ease-in-out;
    -moz-transition: width 0.2s ease-in-out;
    -o-transition: width 0.2s ease-in-out;
    transition: width 0.2s ease-in-out;

    &.compact {
      background: url(https://local.evedo.co/img/web/calendar-shadow.jpg) repeat-y top right;
    }

    .box-header {
      font-size: 20px;
      line-height: 56px;
      font-family: 'Bilo', 'Poppins', 'Helvetica', 'Arial', 'sans-serif';
      color: #151a40;
      font-weight: 500;
      margin: 0;
      color: inherit;
      letter-spacing: -0.5px;
      text-rendering: optimizelegibility;
    }

    .change-month {
      background: #10345d url(https://local.evedo.co/img/web-v2/icons/arrow-left-white.gif) no-repeat center center;
      background-size: 11px 11px;
      display: block;
      height: 25px;
      position: absolute;
      right: 46px;
      top: 15px;
      width: 25px;

      &.next {
        background-image: url(https://local.evedo.co/img/web-v2/icons/arrow-right-white.gif);
        background-size: 11px 11px;
        right: 20px;
      }

      span {
        display: none;
      }
    }

    table {
      border: 1px solid #d2d2d2;
      width: 100%;
      table-layout: fixed;
      caption-side: bottom;
      border-collapse: collapse;
      background-color: transparent;
      border-spacing: 0;

      thead {
        td {
          background: #f8f8f8;
          border-right: none;
          color: #888;
          font-size: 10px;
          height: 24px;
          padding: 0px;
          text-align: center;
          text-transform: uppercase;
          vertical-align: middle;
        }
      }

      td {
        border-bottom: 1px solid #d2d2d2;
        border-right: 1px solid #d2d2d2;
        vertical-align: top;
        width: 14.285714285714285714285714285714%;
        max-width: 14.285714285714285714285714285714%;

        &.inactive {
          color: #d2d2d2;
        }

        &.today {
          background: #f0f0f0;

          span {
            color: #10345d;
            font-weight: bold;
          }
        }

        .cell {
          min-height: 68px;
          height: 100%;
          position: relative;

          &:hover {
            .featured {
              cursor: pointer;
              display: block;
            }
          }

          .featured {
            background: #e62e83 url(https://local.evedo.co/img/web-v2/icons/search-white.png);
            background-size: 30px 30px;
            display: none;
            height: 30px;
            left: 50%;
            margin: -15px 0px 0px -15px;
            position: absolute;
            top: 50%;
            width: 30px;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            border-radius: 15px;

            &.featured-selected {
              display: block;
            }
          }
        }

        span {
          display: block;
          padding: 0px 6px 0px 0px;
          text-align: right;
        }
      }
    }
  }
}
