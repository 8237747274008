.messaged-friend-card {
  border-top: 1px solid #e2e2e2;
  list-style-type: none;

  a {
    width: 100%;
    background: white;
    color: #444;
    display: block;
    padding: 10px;
    position: relative;
    text-decoration: none;

    font-family: "Poppins", "HelveticaNeue", "Helvetica", "Arial", "sans-serif";
    font-size: 16px;
    line-height: 22px;

    &.active {
      background: #10345d;
      color: white;

      .last-msg {
        color: rgba(255, 255, 255, 0.75);
      }

      .unread-count {
        background: white;
        color: #10345d;
      }
    }

    img {
      height: 40px;
      width: 40px;
    }

    strong {
      left: 60px;
      top: 14px;
    }

    .last-msg {
      color: #888;
      font-size: 11px;
      left: 60px;
      top: 29px;
      max-width: 128px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .unread-count {
      background: #e62e83;
      color: white;
      font-size: 10px;
      height: 15px;
      line-height: 15px;
      padding: 0px 5px;
      right: 20px;
      text-align: center;
      top: 22px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
    }

    strong,
    span {
      display: inline-block;
      position: absolute;
    }
  }
}
