.event-edit-list {
  list-style: none;
  text-align: left;
  li {
    list-style-type: none;
    height: 35px;
    a,button {
      opacity: 0.8;
      font-size: 12px;
      color: #10345d;
    }
    button{
      background: transparent;
    }
  }
}
