.modal-wrapper {
  display: block;
  z-index: 10;

  .modal-dim {
    width: 100vw;
    height: 100vh;
    background: black;
    opacity: 0.3;
    position: fixed;
    top: 0;
  }

  .modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    margin: auto;
    transform: translate(-50%, -50%);

    &.sitting-chart-wrapper {
      max-width: 80%;
    }
  }
}
