.promoters-title {
  font-family: "Bilo", "Poppins", Helvetica, Arial, sans-serif;
  color: #23232e;
  font-size: 2rem;
  line-height: 30px;
  font-weight: 700;
}
.promoter-title {
  font-weight: 700;
  font-family: "Bilo", "Poppins", Helvetica, Arial, sans-serif;
  background: linear-gradient(0deg, #000 0%, rgba(26, 32, 88, 0) 100%);
  padding: 0 20px 20px;
  z-index: 1;
  bottom: 0;
  height: 50%;
  display: flex;
  align-items: flex-end;
  font-size: 1vw;
  @media all and (max-width: 700px) {
    font-size: 0.875rem;
  }
}
