.interactions-wrapper {
  h1 {
    font-size: 20px;
    line-height: 56px;
    font-family: "Bilo", "Poppins", "Helvetica", "Arial", "sans-serif";
    color: #151a40;
    font-weight: 500;
    margin: 0;
    color: inherit;
    letter-spacing: -0.5px;
    text-rendering: optimizelegibility;
  }

  nav {
    button {
      color: #10345d;
      font-family: "Poppins", "HelveticaNeue", "Helvetica", "Arial", "sans-serif";
      font-size: 16px;
      line-height: 22px;
    }
  }

  .events-list {
    list-style-type: none;
    padding: 0px;
    overflow: hidden;
  }
}
