.calendar-cell-tooltip {
  background: white;
  height: auto;
  left: 50%;
  margin: 15px 0px 0px -115px;
  position: absolute;
  top: 50%;
  width: 230px;
  z-index: 10;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 25px rgba(2, 1, 1, 0.25);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);

  &:before {
    border-color: transparent transparent white transparent;
    border-style: solid;
    border-width: 0px 12px 12px 12px;
    content: "";
    height: 0px;
    left: 50%;
    margin-left: -12px;
    position: absolute;
    top: -12px;
    width: 0px;
  }

  h2 {
    border-bottom: 1px solid #e2e2e2;
    height: 40px;
    font-family: "HelveticaNeue", "Helvetica", "Arial", "sans-serif";
    font-size: 15px;
    font-weight: bold;
    line-height: 40px;
    text-align: center;
    width: 230px;
  }

  ul {
    list-style-type: none;
    margin: 5px 0px;
    padding: 0px;
    max-height: 300px;
    overflow: auto;
    width: 230px;

    .message {
      color: #888;
      height: 50px;
      text-align: center;
      font-family: "Poppins", "HelveticaNeue", "Helvetica", "Arial", "sans-serif";
      font-size: 16px;
      line-height: 22px;
    }

    &.list {
      max-height: 85px;
    }
  }
}
