@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./_plentixVariables";
@import "./app/Common/Form/Avatar";

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("#{$domain}/fonts/poppins/Poppins-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url("#{$domain}/fonts/poppins/Poppins-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url("#{$domain}/fonts/poppins/Poppins-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url("#{$domain}/fonts/poppins/Poppins-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Bilo";
  font-style: normal;
  font-weight: 500;
  src: url("#{$domain}/fonts/bilo/Bilo-Medium.woff") format("woff");
}

@font-face {
  font-family: "Bilo";
  font-style: normal;
  font-weight: 700;
  src: url("#{$domain}/fonts/bilo/Bilo-Bold.woff") format("woff");
}

@font-face {
  font-family: "Bilo";
  font-style: normal;
  font-weight: 800;
  src: url("#{$domain}/fonts/bilo/Bilo-ExtraBold.woff") format("woff");
}
body {
  overflow-x: hidden;
  padding-right: 0px !important;
  // margin: 0 0 100px 0;
  // background-color: #f6f5fa;
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  background-color: #f6f5fa;
  // font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  // font-family: "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: #444;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Bilo", "Poppins", Helvetica, Arial, sans-serif;
  //  font-family: 'Maven Pro', sans-serif;
  color: #151a40; //#10345D !important
  font-weight: 500;
  margin: 0;
  color: inherit;
  letter-spacing: -0.5px;
  text-rendering: optimizelegibility;
}

h1 {
  font-size: 20px;
  line-height: 56px;
}

h3 {
  font-size: 20px;
  line-height: 24px;
}

img {
  max-width: 100%;
}
/*
  * LINKS
  */
a:hover {
  text-decoration: none;
  transition: 0.3s all;
}

.ui-datepicker {
  background-color: white;
}

.when input {
  display: inline-block;
}

.mask {
  overflow: hidden;
}

.text-dark {
  color: #23232e !important;
}

.btn-outline-secondary {
  border: 1px solid rgba(120, 125, 130, 0.1);
  border-radius: 2px;
  font-weight: 600;
  color: rgba(69, 69, 68, 0.8);
  padding: 0.25rem 1rem;
}
.btn-outline-secondary:hover {
  border-color: rgba(120, 125, 130, 0.2);
  background-color: #f2f2f5;
}
.marker-green {
  color: $rsvp1;
  .selected,
  &:hover {
    background-color: $rsvp1;
    i {
      color: white !important;
    }
  }
}
.marker-yellow {
  color: $rsvp2;
  .selected,
  &:hover {
    background-color: $rsvp2;
    i {
      color: white !important;
    }
  }
}
.marker-pink {
  color: $rsvp3;
  .selected,
  &:hover {
    background-color: $rsvp3;
    i {
      color: white !important;
    }
  }
}
.marker-blue {
  color: $rsvp4;
  .selected,
  &:hover {
    background-color: $rsvp4;
    i {
      color: white !important;
    }
  }
}

.pac-container {
  z-index: 10000 !important;
}

/* Bootstrap update */

.h1,
.h2,
about-title,
.h4,
.h5,
.h6,
.text-bilo {
  font-family: "Bilo", "Poppins", Helvetica, Arial, sans-serif;
}
.h1 {
  font-size: 6.6875rem;
  line-height: 75px;
}
.h2 {
  font-size: 3.5rem;
  line-height: 56px;
}
.h3 {
  font-size: 2.625rem;
  line-height: 44px;
}
.h4 {
  font-size: 2rem;
  line-height: 36px;
}
.h5 {
  font-size: 1.5rem;
  line-height: 30px;
}
.h6 {
  font-size: 1.125rem;
  line-height: 24px;
}
.text-xl {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 38px;
}
.text-l {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 28px;
}
.text-m {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 26px;
}
.text-s {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 22px;
  letter-spacing: 0.01rem;
}
.text-xs {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 20px;
  letter-spacing: 0.01rem;
}

.label-l {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;
}
.label-m {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 600;
  font-size: 0.8125rem;
  line-height: 24px;
}
.label-s {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 600;
  font-size: 0.6875rem;
  line-height: 24px;
}

.link-bold {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 16px;
  color: $dark;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    background: $link-pink;
  }
  &:hover {
    color: $link-pink;
    ::after {
      width: 0;
    }
  }
}

.box {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.btn-primary {
  background-color: #e62e83;
  border: 0;
  border-radius: 2px;

  &:hover,
  &:active,
  &:focus {
    background-color: #d60d6a;
    border-color: #d60d6a;
  }
}

.btn-outline-primary {
  color: #10345d;
  border-color: #10345d;

  &:hover {
    color: #ffffff;
    background-color: #10345d;
    border-color: #10345d;
  }
}

.btn-secondary {
  background-color: #10345d;
  border: 0;
  border-radius: 2px;
  padding: 0.25rem 1rem;

  &:hover {
    background-color: #204875;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.735rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 2px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.hint {
  background: #d2d2d2 url(https://local.evedo.co/img/web-v2/common/hint-question.png);
  background-size: 13px 13px;
  display: inline-block;
  height: 13px;
  vertical-align: -2px;
  width: 13px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
