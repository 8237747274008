@import "../../definitions";

div.ticket-preview > div:nth-child(3) div:nth-child(2),
div.ticket-preview > div:nth-child(3) div:nth-child(3),
div.ticket-preview > div:nth-child(3) div:nth-child(4),
div.ticket-preview > div:nth-child(3) div:nth-child(5) {
  background: #f0f0f0;
  float: left;
  height: 5px;
  margin: 0px 0px 5px 13px;
  width: 60px;
}

div.tickets {
  background: url(#{$domain}/img/web-v2/common/tickets-finish-gradient.jpg) no-repeat bottom left;
  background-size: 100% 115px;
  height: 231px;
  overflow: hidden;
  position: relative;
  width: 100%;
  .ticket-preview {
    bottom: -11px;
    left: 50%;
    margin-left: -75px;
    position: absolute;
    background: #ffffff;
    cursor: default;
    height: 200px;
    overflow: hidden;
    width: 150px;
    border-style: soild;
    border-width: 1px;
    display: inline-block;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-user-select: none;
    -webkit-transform: translateY(205px);
    -moz-transform: translateY(205px);
    transform: translateY(205px);
    -webkit-transform-origin: bottom center;
    -moz-transform-origin: bottom center;
    transform-origin: bottom center;
    -webkit-transition: -webkit-transform 0.5s ease 0.4s;
    -moz-transition: -moz-transform 0.5s ease 0.4s;
    transition: transform 0.5s ease 0.4s;
  }
}
div.tickets.spread div.ticket-preview:nth-child(1) {
  -webkit-transform: translateY(0px) rotate(0deg);
  -moz-transform: translateY(0px) rotate(0deg);
  transform: translateY(0px) rotate(0deg);
  z-index: 6;
}
div.ticket-preview > div:nth-child(1) {
  border-bottom: 1px solid #f0f0f0;
  height: 57px;
  div:nth-child(1) {
    font-size: 12px;
    font-weight: bold;
    margin: 7px 13px 0px 13px;
    text-align: left;
    width: 124px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  div:nth-child(2),
  div:nth-child(3) {
    background: #f0f0f0;
    height: 5px;
    margin: 0px 0px 5px 13px;
    width: 95px;
  }
  div:nth-child(3) {
    width: 85px;
  }
}

div.ticket-preview > div:nth-child(2) {
  border-bottom: 1px solid #f0f0f0;
  height: 50px;
  div {
    background: #f0f0f0;
    height: 5px;
    margin: 0px 0px 5px 13px;
    width: 95px;
  }
  div:nth-child(1) {
    margin-top: 10px;
    width: 25px;
  }
  div:nth-child(2) {
    width: 34px;
  }
  div.ticket-preview > div:nth-child(2) div:nth-child(2) {
    width: 34px;
  }
  div:nth-child(3) {
    margin-top: 10px;
    width: 30px;
  }
  div:nth-child(4) {
    width: 54px;
  }
}
div.ticket-preview > div:nth-child(3) {
  height: 58px;
  overflow: hidden;
  div:nth-child(1) {
    background: url(#{$domain}/qr/?code=evedo);
    background-size: 38px 38px;
    float: left;
    height: 38px;
    margin: 10px 0px 0px 13px;
    width: 38px;
  }

  div:nth-child(2) {
    margin-top: 10px;
    width: 25px;
  }
  div:nth-child(3) {
    margin-right: 30px;
    width: 29px;
  }
  div:nth-child(4) {
    margin-top: 3px;
    width: 40px;
  }
}
div.ticket-preview > div:nth-child(4) {
  background: #10345d;
  height: 16px;
}

.text-ticket {
  position: relative;
  width: 100%;
  h2 {
    padding-top: 43px;
  }
  .finish div.text h2 + p {
    padding-bottom: 0px;
  }
}
.success {
  background: #12982b url(#{$domain}/img/web-v2/common/success.png);
  background-size: 44px 44px;
  height: 44px;
  left: 50%;
  margin: -26px 0px 0px -26px;
  position: absolute;
  top: 0px;
  width: 44px;
  z-index: 10;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
