div.input-group {
  border: none !important;
}

.btn-primary.active {
  color: #ffffff !important;
  background-color: #0d2a4a !important;
  border-color: #0c2746 !important;
}
.tickets-select {
  opacity: 0.8;
  padding-left: 9px;
  height: 36px;
  background-color: #ffffff;
  border: 1px solid #d2d2d2;
}
