@import "../../../_definitions";

.skeleton {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -2px;
    bottom: -2px;
    background: $grey;
  }
}
.location-wrap {
  border: 1px solid #d6d3df;
  box-sizing: border-box;
  border-radius: 32px;
}
