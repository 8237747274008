.checkout-email-wrapper {
  padding: 30px 0 16px 0;
  border-bottom: 1px solid #d6d3df;

  .email-container {
    .email {
      font-weight: 600;
      color: #201f22;
    }

    .change-btn {
      font-size: 12px;
      line-height: 18px;
      color: #2b7fe5;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
