.checkout-required-info-form {
  max-width: 451px;
  background: #fff;
  @media (min-width: 768px) {
    padding: 0 !important;
  }

  .form-heading {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.01em;
    color: #23232e;
  }

  .checkout-input {
    margin-bottom: 16px;
  }

  .checkout-required-fields {
    padding-bottom: 38px;
    border-bottom: 1px solid #d6d3df;

    .login-p {
      font-size: 12px;
      line-height: 18px;
      color: #49494f;

      .login-link {
        color: #49494f;
        font-weight: 400;
        font-size: 12px;

        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.checkout-unrequired-fields {
  margin-top: 40px;
}
