.sitting-chart-preview-container {
  max-width: 227px;
  margin-top: 37px;
  padding: 0 !important;

  button {
    font-size: 12px;
    line-height: 18px;
    color: #6e6d76;
  }
}
