@import "../../_definitions";

body {
  background-size: contain;
  background-repeat: no-repeat;
}
.maincta-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  margin: 0rem auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url(#{$domain}/img/organizers/img-pattern.png),
    linear-gradient(117deg, #ffedee, #ffd7f3 52%, #f2efff);
  background-position: 100% 100%, 0px 0px;
  background-size: 1214px, auto;
  background-repeat: no-repeat, repeat;
  @media screen and (max-width: 479px) {
    margin-top: 0rem;
    margin-bottom: 0rem;
    border-radius: 0px;
    background-image: linear-gradient(117deg, #ffedee, #ffd7f3 52%, #f2efff);
    background-position: 0px 0px;
    background-size: auto;
    background-repeat: repeat;
  }
  h2 {
    margin-top: 10px;
    margin-bottom: 2rem;
    font-family: Bilo, sans-serif;
    color: #151a40;
    font-size: 3.4rem;
    line-height: 110%;
    font-weight: 700;
    letter-spacing: -0.5px;
    text-transform: capitalize;
  }
  h4 {
    margin-top: 10px;
    margin-bottom: 1rem;
    font-family: Bilo, sans-serif;
    color: #151a40;
    font-size: 1.5rem;
    line-height: 130%;
    font-weight: 700;
    letter-spacing: -0.5px;
    text-transform: none;
    @media screen and (max-width: 479px) {
      font-size: 1.2rem;
    }
  }
}
.heading-5.cta-heading {
  margin-bottom: 1rem;
}
.heading-5 {
  text-align: center;
  @media screen and (max-width: 991px) {
    margin-bottom: 1rem !important;
    font-size: 3rem !important;
  }
  @media screen and (max-width: 479px) {
    margin-bottom: 0.5rem !important;
    font-size: 1.8rem !important;
  }
}

.subtitle {
  font-family: Poppins, sans-serif;
  color: #23232e;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0px;
}
.container-medium.flexed {
  @media screen and (max-width: 479px) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
