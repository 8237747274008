@import "../../../../definitions";

.codes-wrapper {
  width: 750px;
  .highlight {
    background-color: rgba(255, 0, 0, 0.55);
    padding-left: 0;
    padding-right: 0;
  }
  ul {
    height: 350px;
    list-style-type: none;
    margin: 50px 0px 0px 0px;
    overflow: auto;
    width: 800px;
    padding-left: 2rem;
  }
  li {
    border-bottom: 1px solid #f0f0f0;
  }

  div.code {
    background: #ffffff;
    height: 60px;
    position: relative;
    div.pair {
      color: #888;
      height: 60px;
      float: left;
      width: 100px;
      span.label {
        display: block;
        font-size: 11px;
      }
      span.value {
        display: block;
        font-size: 20px;
        font-weight: bold;
        padding-top: 1px;
        padding-bottom: 3px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    div.pair.categories {
      margin-right: 28px;
      width: 102px;
      div {
        float: left;
        height: 16px;
        margin-right: 2px;
        margin-top: 2px;
        width: 15px;
        border-radius: 2px;
      }
    }
    .placeholder {
      background: #f0f0f0;
      border: 1px solid #e8e8e8;
      font-weight: bold;
      line-height: 14px;
      text-indent: 1px;
      text-align: center;
    }
    .pair.categories div {
      float: left;
      height: 16px;
      margin-right: 2px;
      margin-top: 2px;
      width: 15px;
      border-radius: 2px;
    }
    label.new-switch input {
      display: none;
    }
    label.new-switch div.labels {
      background: #d2d2d2;
      height: 24px;
      overflow: hidden;
      position: relative;
      width: 60px;
      -webkit-border-radius: 12px;
      -moz-border-radius: 12px;
      border-radius: 12px;
      -webkit-mask-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC");
      span {
        color: #ffffff;
        display: block;
        float: left;
        height: 24px;
        left: 0px;
        line-height: 24px;
        position: absolute;
        text-align: center;
        top: 0px;
        width: 48px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transition: all 0.15s ease-in-out 0s;
        -moz-transition: all 0.15s ease-in-out 0s;
        transition: all 0.15s ease-in-out 0s;
      }
    }

    label.new-switch div.labels span:first-child {
      font-weight: bold;
      left: -36px;
      padding-right: 8px;
    }
    label.new-switch div.labels span:last-child {
      left: 12px;
      padding-left: 8px;
    }
  }
  .arrow {
    background: url("#{$domain}/img/web-v2/icons/arrow-right-gray.gif") no-repeat top left;
    background-size: 11px 11px;
    height: 12px;
    left: 24px;
    position: absolute;
    top: 25px;
    width: 12px;
    @include transformTransition(0.2s, ease-in-out);
  }
  .arrow-right {
    transform: none;
  }
  .arrow-down {
    transform: rotate(90deg);
  }
  a.name {
    color: #444;
    display: block;
    float: left;
    font-size: 16px;
    font-weight: bold;
    height: 60px;
    line-height: 60px;
    margin-right: 10px;
    padding: 0px 0px 0px 60px;
    text-decoration: none;
    width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  div.pair.discount {
    margin-right: 10px;
    width: 100px;
  }
  .details {
    background: #f8f8f8;
    height: 0px;
    overflow: hidden;
    .period {
      margin: 0px;
      padding: 22px 0px 21px 0px;
      text-align: center;
      strong {
        font-weight: bolder;
      }
    }
    .action {
      border-top: 1px solid #e8e8e8;
      margin-left: 60px;
      padding: 18px 0px;
      text-align: center;
      width: 630px;
      a {
        height: 24px;
        line-height: 24px;
        padding: 0px 6px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
      }
    }
  }
  .new-switch {
    color: transparent;
    cursor: pointer;
    height: 26px;
    margin: 0px;
    overflow: hidden;
    padding: 0px !important;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    width: 60px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    font-size: 12px;

    &.unchecked {
      .on {
        left: -60px;
        z-index: 1;
      }

      .thumb {
        left: 0px;
      }

      .off {
        left: 0px;
        z-index: 2;
      }
    }

    span {
      display: block;
      height: 26px;
      line-height: 26px;
      position: absolute;
      top: 0px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      -webkit-transition: left 0.1s ease-in-out;
      -moz-transition: left 0.1s ease-in-out;
      -o-transition: left 0.1s ease-in-out;
      transition: left 0.1s ease-in-out;

      &.on {
        background: #12982b;
        color: white;
        left: 0px;
        padding: 0px 16px 0px 0px;
        width: 60px;
        z-index: 2;
      }

      &.thumb {
        background: red;
        height: 25px;
        left: 39px;
        border-radius: 50%;
        width: 21px;
        z-index: 3;
        -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
      }

      &.off {
        background: #d2d2d2;
        color: white;
        left: 44px;
        padding: 0px 0px 0px 16px;
        width: 60px;
        z-index: 1;
      }
    }
  }
  label.new-switch input:checked ~ div.labels span:first-child {
    background: #12982b;
  }
}

.btn-create{
  border: #12982b;
  background-color: #12982b;
  width: fit-content;
  border-radius: 10%;
  font-size: 12px;
  padding: 5px 8px;
}
