form {
  margin: 0 0 18px;
  label {
    cursor: pointer !important;
  }
  .custom-browse {
    background: #10345d;
    color: #d9f1f8;
    font-size: 11px;
    height: 40px;
    line-height: 14px;
    padding: 6px;
    position: relative;
    text-align: center;
    width: 100%;
    input {
      font-size: 180px;
      left: 0px;
      display: none;
      cursor: pointer;
      top: 0px;
      height: 100%;
      position: absolute;
      width: 100%;
      line-height: 28px;
    }
    strong {
      color: white;
      font-size: 16px;
    }
  }
}
