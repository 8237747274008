.interaction-card {
  background-color: white;
  display: inline-block;
  position: relative;
  -webkit-box-shadow: 0px 1px 3px #dfd5d5;
  -moz-box-shadow: 0px 1px 3px #dfd5d5;
  box-shadow: 0px 1px 3px #dfd5d5;

  .mask {
    display: block;
    overflow: hidden;

    img {
      aspect-ratio: 23/17;
    }
  }

  .category {
    background: #10345d;
    color: white;
    padding: 1px 12px 0px 12px;
    left: 0px;
    position: absolute;
    text-decoration: none;
    text-transform: uppercase;
    z-index: 1;

    &:hover {
      background: #e62e83;
      transition: 0.3s all;
    }

    @media (min-width: 1200px) {
      bottom: 100px;
    }

    @media (min-width: 768px) and (max-width: 1200px) {
      bottom: 130px;
    }
  }

  .title {
    color: #444;
    display: block;
    height: 40px;
    line-height: 15px;
    margin-bottom: 6px;
    overflow: hidden;
    padding: 8px 12px 0px 12px;
    text-decoration: none;
    width: 206px;
    text-align: left;
  }

  span {
    color: #888;
    display: block;
    float: left;
    overflow: hidden;
    padding: 3px 0px 4px 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 135px;
  }

  time {
    color: #888;
    float: right;
    padding: 3px 12px 4px 0px;
    display: inline;
  }

  .marker {
    border-top: 1px solid white;
    clear: both;
    color: white;
    font-weight: bold;
    height: 25px;
    line-height: 26px;
    text-align: center;
    text-transform: uppercase;
    font-family: "Poppins", "HelveticaNeue", "Helvetica", "Arial", "sans-serif";
    font-size: 16px;
  }

  .marker-green:hover {
    background: #12982b;
  }

  .marker-pink:hover {
    background: #e62e83;
  }

  .marker-yellow:hover {
    background: #f6a131;
  }
}
