.msg-conversation {
  min-height: 400px;
  overflow: hidden;
  position: relative;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  font-family: "Poppins", "HelveticaNeue", "Helvetica", "Arial", "sans-serif";

  .start-conversation-msg {
    color: #d2d2d2;
    font-family: "Georgia", "Times New Roman", "serif";
    font-size: 20px;
    line-height: 24px;
    padding-top: 50px;
    text-align: center;
  }

  .conversation-header {
    background: white;
    border-bottom: 1px solid #e2e2e2;
    color: #888;
    font-size: 12px;
    left: 0px;
    line-height: 18px;
    padding: 10px;
    position: absolute;
    top: 0px;
    z-index: 1;
    -webkit-transition: box-shadow 0.2s linear;
    -moz-transition: box-shadow 0.2s linear;
    -o-transition: box-shadow 0.2s linear;
    transition: box-shadow 0.2s linear;

    .image-container {
      float: left;
      margin-right: 10px;
      .profile-image {
        height: 50px;
        width: 50px;
      }
    }

    span {
      display: block;
      margin-top: 7px;
    }

    .name {
      color: #444;
      font-size: 14px;
      font-weight: bold;
      text-decoration: none;

      &:hover {
        color: #10345d;
      }
    }
  }

  .new-msg-form {
    background: white;
    border-top: 1px solid #e2e2e2;
    bottom: 0px;
    left: 0px;
    margin: 0px;
    padding: 10px;
    position: absolute;

    textarea {
      resize: none;
    }
  }
}
