.checkout-checkbox {
  width: 32px;
  height: 32px;
  background: #fee7f4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  flex-shrink: 0;
}
