@import "../../../_definitions";

section.profile-setup {
  height: 100vh !important;
  background: #ffffff;
}

section.profile-setup div.mask.steps {
  border-bottom: 1px solid #e8e8e8;
  min-height: 430px;
  width: 860px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.mask {
  overflow: hidden;
}

section.profile-setup div.mask.steps .wrapper {
  height: 100%;
  width: 4300px;
}
section.profile-setup .wrapper {
  -webkit-transition: -webkit-transform 0.5s ease-in-out 0s;
  -moz-transition: -moz-transform 0.5s ease-in-out 0s;
  transition: transform 0.5s ease-in-out 0s;
}
section.profile-setup .wrapper .slide {
  float: left;
  position: relative;
  width: 860px;
  overflow: hidden;
  p {
    color: #888888;
    font-size: 16px;
    line-height: 25px;
    padding: 0px 20px 14px 20px;
    text-align: center;
  }
}
body {
  background-size: cover;
  margin-bottom: 0px !important;
}
button{
  border:none;
}