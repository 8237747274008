.calendar-event-card {
  -webkit-transition: left 0.2s ease-in-out, width 0.2s ease-in-out;
  -moz-transition: left 0.2s ease-in-out, width 0.2s ease-in-out;
  -o-transition: left 0.2s ease-in-out, width 0.2s ease-in-out;
  transition: left 0.2s ease-in-out, width 0.2s ease-in-out;
  overflow: hidden;
  position: relative;

  .event-info {
    background: white;

    .close {
      background: rgba(0, 0, 0, 0.25) url(https://local.evedo.co/img/web/icon-remove-white.gif) no-repeat center center;
      display: block;
      height: 26px;
      position: absolute;
      right: 0px;
      top: 0px;
      width: 26px;
      float: right;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;
      color: #000;
      text-shadow: 0 1px 0 #ffffff;
      opacity: 0.5;
    }

    .event-card-img-container {
      width: 100%;
      background: #d2d2d2 url(https://local.evedo.co/img/web/evedo.jpg) no-repeat center center;
      display: block;
      height: 170px;
      text-align: center;
      overflow: hidden;

      img {
        display: inline;
      }
    }

    time {
      background: #10345d;
      display: block;
      font-size: 14px;
      font-weight: bold;
      height: 30px;
      line-height: 30px;
      text-align: center;
      text-transform: uppercase;
      color: white;
    }

    .event-title {
      color: #444;
      display: block;
      font-family: "Georgia", "Times New Roman", "serif";
      font-size: 16px;
      line-height: 20px;
      padding: 17px 20px 16px 20px;
      text-transform: uppercase;
    }

    .details {
      margin: 15px 20px;
      width: 190px;

      td {
        font-family: "Poppins", "HelveticaNeue", "Helvetica", "Arial", "sans-serif";
        font-size: 16px;
        line-height: 22px;
        color: #444;

        a {
          color: #0d2a4a;
        }
      }
    }

    .marker {
      font-weight: bold;
      height: 24px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      margin-left: 20px;
      margin-right: 20px;

      &.marker-green {
        color: #12982b;

        &:hover {
          background: #12982b;
        }
      }

      &.marker-yellow {
        color: #f6a131;

        &:hover {
          background: #f6a131;
        }
      }

      &.marker-pink {
        color: #e62e83;

        &:hover {
          background: #e62e83;
        }
      }

      &:hover {
        color: white;
      }
    }
  }
}
