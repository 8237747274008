@import "../../../definitions";

.checkbox {
  min-height: 18px;
  padding-left: 18px;
}
label input[type="checkbox"].custom,
label input[type="radio"].custom {
  display: none;
}
input[type="checkbox"] {
  float: left;
}
label input[type="checkbox"].custom + span {
  background: url(#{$domain}/img/web-v2/common/custom-checkbox.png);
  background-size: 14px 26px;
  cursor: pointer;
  display: inline-block;
  height: 13px;
  margin-right: 4px;
  vertical-align: -2px;
  width: 14px;
}
label input[type="checkbox"].custom:checked + span,
label input[type="radio"].custom:checked + span {
  background-position: bottom left;
}
.dataTables_wrapper {
  overflow: hidden;
  position: relative;
}
.dataTables_wrapper .dataTables_length {
  color: #888;
  float: left;
  padding: 10px 0px 10px 10px;
  select {
    margin: 0px 5px 0px 0px;
    width: 60px;
  }
}
.dataTables_wrapper .dataTables_filter {
  color: #888;
  float: right;
  padding: 10px 10px 10px 0px;
  input {
    width: 238px;
  }
}
table.dataTable {
  background: #ffffff;
  border-bottom: 4px solid #10345d;
  border-top: 4px solid #10345d;
  font-size: 12px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.table > thead {
  vertical-align: bottom;
}
table caption + thead tr:first-child th,
.table caption + thead tr:first-child td,
.table colgroup + thead tr:first-child th,
.table colgroup + thead tr:first-child td,
.table thead:first-child tr:first-child th,
.table thead:first-child tr:first-child td {
  border-top: 0;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}
table.dataTable thead th.sorting,
table.dataTable thead th.sorting_asc,
table.dataTable thead th.sorting_desc {
  cursor: pointer;
}
table.dataTable thead th {
  border-bottom: 1px solid #e8e8e8;
  font-weight: bold;
  height: 50px;
}
.table thead th {
  vertical-align: bottom;
}
table.dataTable thead th.sorting_desc:after {
  border-color: transparent transparent #ae102e transparent;
  border-style: solid;
  border-width: 0px 5px 6px 5px;
  height: 0px;
  width: 0px;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}
table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after {
  content: "";
  display: inline-block;
  margin-left: 4px;
  margin-bottom: 1px;
  border-color: transparent transparent #12982b transparent;
  border-style: solid;
  border-width: 0px 5px 6px 5px;
  height: 0px;
  width: 0px;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: -webkit-transform 0.1s ease 0s;
  -moz-transition: -moz-transform 0.1s ease 0s;
  transition: transform 0.1s ease 0s;
}
table.dataTable tbody tr td {
  height: 40px;
  text-align: center;
}
ul.token-list {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
ul.token-list li {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  display: inline-block;
  height: 22px;
  line-height: 22px;
  margin: 0px 3px 3px 0px;
  padding-left: 10px;
  vertical-align: middle;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -webkit-box-shadow: 1px 1px 0px 0px rgb(0 0 0 / 2%);
  -moz-box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.02);
  box-shadow: 1px 1px 0px 0px rgb(0 0 0 / 2%);
}
ul.token-list li span:last-child {
  padding-right: 10px;
}
ul.token-list li span {
  display: block;
  float: left;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table th,
.table td {
  padding: 8px;
  line-height: 18px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #d2d2d2;
}
label input[type="checkbox"].circular,
label input[type="radio"].circular {
  display: none;
}
table.dataTable tbody tr td.sorting_1 {
  background: rgba(71, 211, 95, 0.1) !important;
}
label input[type="checkbox"].circular + span,
label input[type="radio"].circular + span {
  background: #ffffff;
  border: 1px solid #d2d2d2;
  display: block;
  float: left;
  height: 16px;
  vertical-align: 0px;
  width: 16px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
label input[type="checkbox"].circular:checked + span,
label input[type="radio"].circular:checked + span {
  background: url(#{$domain}/img/web-v2/common/tick-pink.jpg) center center;
  background-size: 20px 20px;
  border: none;
  height: 18px;
  width: 18px;
}
label input[type="checkbox"].circular:checked + span,
label input[type="radio"].circular:checked + span {
  background-image: url(#{$domain}/img/web-v2/common/tick-pink.jpg);
}
table.dataTable thead th.sorting_desc:after {
  border-color: transparent transparent #ae102e transparent;
  border-style: solid;
  border-width: 0px 5px 6px 5px;
  height: 0px;
  width: 0px;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}
table.dataTable tbody tr:nth-child(even) {
  background: #f8f8f8;
}
thead.table-tickets > tr > th {
  text-align: center;
}
