.upcoming-event-card {
  .left-container {
    display: flex;
    flex-direction: column;

    .mask {
      width: 100%;

      img {
        width: 100%;
      }
    }

    time {
      color: #888;
      display: block;
      text-align: center;

      strong {
        background: #10345d;
        color: white;
        display: block;
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        padding-left: 5px;
      }
    }
  }

  .info {
    color: #888;
    height: auto;
    padding-bottom: 20px;
    font-size: 16px;
    line-height: 22px;
    font-family: "Poppins", "HelveticaNeue", "Helvetica", "Arial", "sans-serif";

    .title {
      color: #444;
      display: block;
      font-family: "Georgia", "Times New Roman", "serif";
      font-size: 16px;

      &:hover {
        color: #10345d;
      }
    }

    span {
      color: #d2d2d2;
      padding: 0px 4px;
    }

    .marker {
      display: inline-block;
      height: 21px;
      line-height: 22px;
      margin: 8px 2px 0px 0px;
      padding: 0px 10px;
      text-decoration: none;
      transition: 0.3s all;

      &:disabled {
        cursor: pointer;
      }

      &.marker-green {
        color: #12982b;

        &:hover {
          color: #0d2a4a;
          background-color: #12982b;
        }
      }

      &.marker-yellow {
        color: #f6a131;

        &:hover {
          color: #0d2a4a;
          background-color: #f6a131;
        }
      }

      &.marker-pink {
        color: #e62e83;

        &:hover {
          color: #0d2a4a;
          background-color: #e62e83;
        }
      }

      &.disabled {
        background: #f8f8f8;
        color: #d2d2d2;

        &:hover {
          background: #f8f8f8;
          color: #888;
        }
      }
    }
  }
}
