.recent-activities {
  position: relative;
  text-align: center;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  .box-header {
    font-size: 20px;
    line-height: 56px;
    font-family: "Bilo", "Poppins", "Helvetica", "Arial", "sans-serif";
    color: #151a40;
    font-weight: 500;
    margin: 0;
    letter-spacing: -0.5px;
    text-rendering: optimizelegibility;
  }
}
