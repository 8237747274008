.filters-title {
  font-family: "Bilo", "Poppins", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.sb-item {
  background: #fcfbfd;
  border-radius: 4px;
}
.sb-bold-title {
  color: #616366;
  font-weight: bold;
  line-height: 32px;
  font-family: "Bilo", "Poppins", Helvetica, Arial, sans-serif;
}
.filters-drop-width {
  width: calc(100% - 2rem);
}
.category-filter-dropdown {
  max-height: 60vh;
  overflow-y: auto;
}
.input-tag__tags li {
  background: #e2e3f1;
  color: #49494f;
  list-style: none;
}
.input-tag__tags li.input-tag__tags__input {
  flex-grow: 1;
}
