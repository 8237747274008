@import "./Welcome.scss";

section.profile-setup div.mask.navigation {
  width: 860px;
  .skip {
    line-height: 58px;
    text-align: center;
  }
  section.profile-setup div.mask.navigation .steps a {
    font-size: 22px;
    line-height: 1.5;
  }
}
.search-input {
  max-width: 484px !important;
  background: red;
  // background-image: url(#{$domain}/img/svg/icon-location.svg);
  background-position: 20px center;
  background-repeat: no-repeat;
}
button {
  border: none;
}
