.attender-card {
  float: left;
  display: block;
  float: left;
  height: 51px;
  margin: 0px 2px 2px 0px;
  overflow: hidden;
  position: relative;
  width: 51px;

  img {
    height: 50px;
    width: 50px;
  }
}
