.calendar-cell-event {
  width: 100%;
  display: block;
  font-size: 11px;
  line-height: 14px;
  margin-bottom: 2px;
  overflow: hidden;
  text-decoration: none;
  padding: 2px;
  white-space: nowrap;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;

  &.marker-green {
    background-color: #12982b;
    color: white;
  }

  &.marker-yellow {
    background-color: #f6a131;
    color: white;
  }

  &.marker-pink {
    background-color: #ce5aa4;
    color: white;
  }

  &.active {
    -webkit-box-shadow: inset 0px 20px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: inset 0px 20px 0px rgba(0, 0, 0, 0.25);
    box-shadow: inset 0px 20px 0px rgba(0, 0, 0, 0.25);
  }

  span {
    display: block;
    font-weight: normal !important;
    overflow: hidden;
    padding: 0px;
    text-align: left !important;
    text-overflow: ellipsis;
    -webkit-transition: width 0.2s ease-in-out;
    -moz-transition: width 0.2s ease-in-out;
    -o-transition: width 0.2s ease-in-out;
    transition: width 0.2s ease-in-out;
  }
}
