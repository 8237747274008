@import "../../../_definitions";

.featured-title {
  color: #151a40 !important;
  letter-spacing: -0.02em;
}
.featured-link {
  font-family: "Poppins", "HelveticaNeue", "Helvetica", Arial, sans-serif;
  font-weight: 500;
  line-height: 32px;
  font-size: 0.65625rem;
  display: flex;
  align-items: center;
  color: $dark;
  &:hover {
    color: $link-pink;
  }
}
